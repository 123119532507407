import React, { useState, useEffect } from 'react';
import { Container, Title, NoBatchesDiv } from './styles';

import Cookie from 'js-cookie';
import Helpers from '../../services/helpers/index';
import API from '../../services/API/index';

import MobileBatches from './Mobile';
import LandscapeBatches from './Landscape';
import DesktopBatches from './Desktop';

export default function Batches(){
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );
    const [batches,setBatches] = useState([]);

    async function batchesData(){
        let batchesInfo = []
        API.get('batchesByCompany/' + Cookie.get('sheep-company-id')).then((res)=>{
            if(res.data.length > 0){
                res.data.map((item)=>
                    batchesInfo.push({
                        id : item.id,
                        processment_plants_name: item.processmentPlant,
                        slaughter_plants_name: item.slaughterPlant,
                        processment_plants: item.procesmenPlantNameAndId,
                        slaughter_plants: item.slaughterPlantNameAndId,
                        animals_quantity: item.animalsQtt,
                        slaughter_date: Helpers.formatDate(item.slaughterDate),
                        creation_date:Helpers.formatDate(item.createdAt),
                        status: item.status,
                        evaluation_status:item.classification === "APROVADO"? "Aprovado" : item.classification === "REPROVADO" ? "Reprovado" :item.classification?"Aprovado com ressalvas":"Rascunho" ,
                        isDorper: item.isDorper,
                        supplier: item.supplier,
                        farm: item.farm,
                        productor:item.productor,
                        compliance:item.compliances,
                        races: item.races,
                        deaths: item.deaths,
                        observation: item.observation,
                        hang_method: item.hangMethod,
                        avg_live_weight: item.liveWeight,
                        max_age_declared: item.maxAge,
                        compliance_id: item.compliances.id,
                        shearing_status: item.shearing,
                        ph_average: parseFloat(item.phinitial).toFixed(1),
                        media: item.media??null,
                        media_id: item.media?item.media.id:null
                    })
                )

                batchesInfo.sort((a,b)=> b.id - a.id)
                setBatches(batchesInfo)
            }
        })
    }

    useEffect(()=>{
        batchesData()
    },[])

    const clearLocalStorage = () => {
        localStorage.removeItem('batchState');
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            clearLocalStorage();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1000);
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
 
    return(
        <Container className="tooltipBoundary">
            <Title className='large tooltipBoundary'>Lotes</Title>

            {batches.length > 0 ? 
                <>
                    {(isMobile && isPortrait) ? (
                        <MobileBatches />
                    ) 
               
                    : <>
                        {(isMobile && !isPortrait) ? (
                            <LandscapeBatches/>
                        )
                        
                        : <>
                            <DesktopBatches/>
                          </>
                        } 
                        </>
                    }
                </>
            :
            <NoBatchesDiv>
                Sem lotes cadastrados
            </NoBatchesDiv>
              
            }
                 
        </Container>
    )

}