import React from 'react';
import InputMask from 'react-input-mask';
import {
  FormGroup,
  FormLabel,
  FormInput,
  Title,
  SubmitButton,
  CancelButton,
} from '../styles';
import Modal from '..';

const ModalAddProductor = ({ show, onClose, handleSubmit, productorData, setProductorData, error }) => {
  if (!show) return null;

  const handleChange = (e) => {
    const { id, value } = e.target;
    setProductorData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) {
      setProductorData((prevData) => ({
        ...prevData,
        name: value,
      }));
    }
  };

  return (
    <Modal onClose={onClose} show={show} width={30}>
      <Title>Cadastrar Produtor</Title>
      <form onSubmit={handleSubmit}>
        <FormGroup style={{width:'100%'}}>
          <FormLabel htmlFor="name">Nome do Produtor</FormLabel>
          <FormInput
            type="text"
            id="name"
            value={productorData.name}
            onChange={handleNameChange}
            placeholder="Digite o nome do produtor"
            required
          />
        </FormGroup>
        <FormGroup style={{width:'100%'}}>
          <FormLabel htmlFor="cnpj">CNPJ</FormLabel>
          <InputMask
            mask="99.999.999/9999-99"
            id="cnpj"
            value={productorData.cnpj}
            onChange={handleChange}
            placeholder="Digite o CNPJ"
          >
            {(inputProps) => <FormInput {...inputProps} />}
          </InputMask>
        </FormGroup>
        <FormGroup style={{width:'100%'}}>
          <FormLabel htmlFor="cpf">CPF</FormLabel>
          <InputMask
            mask="999.999.999-99"
            id="cpf"
            value={productorData.cpf}
            onChange={handleChange}
            placeholder="Digite o CPF"
          >
            {(inputProps) => <FormInput {...inputProps} />}
          </InputMask>
        </FormGroup>
        <FormGroup style={{width:'100%'}}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput
            type="email"
            id="email"
            value={productorData.email}
            onChange={handleChange}
            placeholder="Digite o email"
          />
        </FormGroup>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop:'5%' }}>
          <CancelButton type="button" onClick={onClose}>Cancelar</CancelButton>
          <SubmitButton type="submit">Cadastrar</SubmitButton>
        </div>
      </form>
    </Modal>
  );
};

export default ModalAddProductor;
