import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import {
    ModalContainer,
    Container,
    ContentContainer,
    ModalHeader,
    ModalActions,
    PrimaryButton,
    SecondaryButton,
} from './styles';

import { IoWarning } from "react-icons/io5";

// Context
export const Context = createContext();

// Provider
const Warn = ({ children }) => {
  const [warnInfo, setWarnInfo] = useState({
    visible: false,
    showCancelButton: true, 
  });

  const closeWarn = () => {
    setWarnInfo((info) => ({ ...info, visible: false }));
  };

  
  
  const warn = ({
    title = "",
    body = "",
    firstButton = null,
    secondButton = null,
    icon = null,
    component = null,
    size = null,
    marginBottom,
    marginTop,
    showCancelButton = true, 
  }) => {
    setWarnInfo({
      title,
      body,
      firstButton,
      secondButton,
      icon,
      visible: true,
      component,
      size,
      marginBottom,
      marginTop,
      showCancelButton, 
    });
  };

  return (
    <Context.Provider value={{ warnInfo, closeWarn, warn }}>
      {children}
      <MyWarn />
    </Context.Provider>
  );
};

// Hook
const useWarn = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useWarn must be used within a Warn');
  }

  return context;
};

const MyWarn = () => {
  const { warnInfo, closeWarn } = useWarn();
  const modalRef = useRef(null);

  const {
    title,
    body,
    firstButton,
    secondButton,
    icon,
    visible,
    component,
    size,
    marginBottom,
    marginTop,
    showCancelButton, 
  } = warnInfo;

  const ModalContent = (className) => (
    <div style={{ display:'flex', justifyContent:'center', textAlign:'center' }} className={className}>
      {component}
      {body && <p>{body}</p>}
    </div>
  );

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (visible && !e.target.closest('.modal-container')) {
        closeWarn();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [visible, closeWarn]);

  return (
    visible ? (
      <ModalContainer>
        <Container style={{ height: size ? `${size}px` : '200px', width: size ? '600px' : '250px', marginBottom: marginBottom ? marginBottom : '180px', marginTop: marginTop ? marginTop : '0px'}}>
          <ContentContainer className="modal-container">
            {icon && icon.show ? (
              <IoWarning size={40} style={{ color: '#D8190f' }} />
            ) : (
              <></>
            )}
            <ModalHeader className="medium">
              {title || "Título"}
            </ModalHeader>
            <ModalContent className="small">
              {component} 
              {body && <p>{body}</p>}
            </ModalContent>
            <ModalActions>
              {showCancelButton && (
                <PrimaryButton
                  className="button"
                  color={firstButton?.color??'#D9D9D9'}
                  style={{ marginRight: '50px' }}
                  onClick={() => {
                    if (firstButton?.function) {
                      firstButton.function();
                    } else {
                      closeWarn();
                    }
                  }}
                >
                  {firstButton?.label || "Cancelar"}
                </PrimaryButton>
              )}
              <SecondaryButton
                className="button"
                color={secondButton?.color}
                onClick={() => {
                  if (secondButton?.function) {
                    secondButton.function();
                  } else {
                    closeWarn();
                  }
                }}
              >
                {secondButton?.label || "OK"}
              </SecondaryButton>
            </ModalActions>
          </ContentContainer>
        </Container>
      </ModalContainer>
    ) : (
      <></>
    )
  );
};

export { Warn, useWarn };