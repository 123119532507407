import React, {useEffect, useState} from "react";
import { AquilesButton, BiggerContainer, BiggerInput1, ButtonsContainer, Container, ContentContainer, FakeBorder, FakeContainer, Input,
     InputDivisionLeft, InputDivisionMiddle, InputDivisionRight, InputTitle, InputsContainer, NextButton, NextContainer, PelvesButton, Select,
     SmallerInput, SmallersContainer, StyledDatePicker, Title } from "./styles";
import Helpers from "../../services/helpers";
import pt from 'date-fns/locale/pt';
import API from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { setBatchToUpdate } from "../../store/modules/batch/actions";
import Cookies from "js-cookie";
import { format, parse, isAfter } from 'date-fns';
import { useWarn } from "../../context/Modal"; 
import removeImg from '../../assets/images/remove.png'

export default function Batch ({ onNextButtonClick, backScreen, allErrors, setAllErrors, setBatchIsRegistered }) {

    const { batch } = useSelector((state) => state.Reducers);
    const dispatch = useDispatch();

    const editMode = batch.id?true:false;

    const [processmentPlants, setProcessmentPlants] = useState([]);
    const [slaughterPlants, setSlaughterPlants] = useState([]);

    const [slaughterPlant, setSlaughterPlant] = useState(-1);
    const [processmentPlant, setProcessmentPlant] = useState('');
    const [animalsQuantity, setAnimalsQuantity] = useState('');
    const [initialPh, setInitialPh] = useState('');
    const [hangMethod, setHangMethod] = useState('');
    const [slaughterDate, setSlaughterDate] = useState(null);
    const [isSelected, setIsSelected] = useState('');
    const [media, setMedia] = useState(null);
    const [errors, setErrors] = useState({});
    const [animals, setAnimals] = useState(0);
    const [mediaList, setMediaList] = useState([]);

    const { warn, closeWarn } = useWarn();

    useEffect(()=>{
        if(batch && (batch.status === "CREATED" || batch.status == null)){

        getProcessmentPlants()
        getSlaughterPlants()

        setErrors(allErrors);

        setSlaughterPlant(batch.slaughter_plants??-1)
        setProcessmentPlant(batch.processment_plants??'')
        setAnimalsQuantity(batch.animals_quantity??'')
        setInitialPh(batch.ph_average??'')
        setHangMethod(batch.hang_method !== null?batch.hang_method === 0? 'Pelves': 'Aquiles':'')
        setIsSelected(batch.hang_method !== null?batch.hang_method === 0? 'Pelves': 'Aquiles':'')

        setSlaughterDate(batch.slaughter_date??null);
        setMediaList(batch.media ?? []);
        }
    },[])

    async function getProcessmentPlants(){

        await API.get('processmentPlants')
        .then((res)=> {
            let plants = res.data.filter((p)=> p.fridge.id === parseInt(Cookies.get("sheep-fridge-data")))
            setProcessmentPlants(plants);
        })
        .catch((err)=> console.log(err))

    }

    async function getSlaughterPlants(){
        await API.get('slaughterPlants')
        .then((res)=> {
            setSlaughterPlants(res.data)
            if(Object.keys(batch.slaughter_plants).length > 0){
                let splants = res.data;

                splants.forEach((p, index)=>{
                    if(p.id === batch.slaughter_plants.id){
                        setSlaughterPlant(index);
                    }
                })
            }
        })

    }

    const handleFormateDate = (date) => {
        if(date){ 

            setSlaughterDate(date);
            dispatch(setBatchToUpdate({ slaughter_date: format(date,'yyyy-MM-dd HH:mm:ss'), registering:true }))
        }

    }

    const handleInitialPh = (event) => {
        let { value } = event.target;

        let result = Helpers.formatpH(value);

        setInitialPh(result);    
        dispatch(setBatchToUpdate({ ph_average: result, registering:true }));
    };

    const handleAnimalsQuantity = (event) => {
        let { value } = event.target;

        if (/^\d*$/.test(value)) {
            if (value.length > 3) {
                value = value.slice(0, 3);
            }
            setAnimalsQuantity(value); 
            dispatch(setBatchToUpdate({ animals_quantity: value, registering:true}));
        }               
    };

    async function checkData() {
        let batchInfo = Helpers.prepareBatchData({
            slaughterPlant: slaughterPlants[slaughterPlant] ? slaughterPlants[slaughterPlant].id : null,
            processmentPlant: processmentPlants[0].id,
            animalsQuantity,
            initialPh,
            hangMethod,
            slaughterDate,
        });
    
        let preSlaughterInfo = Helpers.preparePreSlaughterData({
            productor: Object.keys(batch.productor).length > 0 ? batch.productor.id : null,
            farm: batch.farm ? batch.farm.id : null,
            aliveWeigth: batch.avg_live_weight ?? '',
            maxAge: batch.max_age_declared ?? '',
            race: batch?.races.length > 0 ? true : null,
            shear: batch.shearing_status ?? null,
            compliance: batch.compliance,
            userId: Cookies.get('sheep-user-id')
        });
    
        const isDorper = batch.races.filter((r) => r.id === 1).length > 0;
    
        if (isDorper && mediaList.length === 0) {
            setAllErrors({ mediaList: true });
            setErrors({ mediaList: true });
            return null;
        }
    
        if (preSlaughterInfo.message === "Incompleto") {
            setAllErrors(preSlaughterInfo.emptyValues);
            backScreen();
            return null;
        } else if (batchInfo.message === "Incompleto") {
            setAllErrors(preSlaughterInfo.emptyValues);
            setErrors(batchInfo.emptyValues);
            return null;
        }
    
        preSlaughterInfo.data.deaths = batch.deaths ?? '';
        preSlaughterInfo.data.observation = batch.observation ?? '';
    
        const mediaListProcessed = await Promise.all(mediaList.map(async (media) => {
            return {
                sheepMedia: await Helpers.URLtoBase64(media.path),
                name: media.file.name,
                userId: Cookies.get('sheep-user-id'),
                companyId: Cookies.get('sheep-company-id'),
                type: media.file.type
            };
        }));
    
        return { 
            batch: { ...batchInfo.data, ...preSlaughterInfo.data },
            races: batch.races,
            mediaList: mediaListProcessed
        };
    }

    async function saveAndNextPage() {
        try {
            setErrors({});
            setAllErrors({});
    
            let payload = await checkData();
            let mediaAux = mediaList.map(media => ({ ...media, file: { ...media.file, name: media.file.name.replace(/\s+/g, '') } }));
    
            if (payload) {
                const today = new Date(); 
                const formattedToday = format(today, "dd/MM/yyyy");
                const formattedSlaughterDate = format(slaughterDate, "dd/MM/yyyy");
                const parsedToday = parse(formattedToday, "dd/MM/yyyy", new Date());
                const parsedSlaughterDate = parse(formattedSlaughterDate, "dd/MM/yyyy", new Date());
    
                if (isAfter(parsedSlaughterDate, parsedToday)) {
                    setErrors({ slaughterDate: true });
                    warn({
                        title: "Atenção",
                        body: "Por favor, selecione uma data de abate anterior ou igual à data de hoje.",
                        showCancelButton: false,
                        secondButton: { label: "Entendi", color: '#A4A4A4' }
                    });
                    return;
                }
    
                payload.batch.ph_average = parseFloat(String(payload.batch.ph_average).replace(',', '.')).toFixed(1);
    
                let newBatch = await API.post('batches', { ...payload.batch });
                let newRaces = await API.post('batchesraces', { races: payload.races, batch_id: newBatch.data.batch.id });
                const newMediaPromises = [];

                for (let index = 0; index < mediaAux.length; index++) {
                    const media = mediaAux[index];

                    try {
                        const response = await API.post('sheepmedia', {
                        userId: Cookies.get('sheep-user-id'),
                        sheepMedia: payload.mediaList[index].sheepMedia,
                        name: media.file.name,
                        companyId: Cookies.get('sheep-company-id'),
                        batchId: newBatch.data.batch.id,
                        type: media.file.type
                        });

                        newMediaPromises.push(response);
                    } catch (error) {
                        console.error('Error uploading media:', error);
                    }

                }

    
                await Promise.all([newBatch, newRaces, ...newMediaPromises])
                .then((response) => {
                    const mediaResponses = response.slice(2);
                    mediaAux = mediaResponses.map(res => ({ ...res.data.sheepMedia, path: res.data?.sheepMedia?.sheep_media_url ?? null }));
            
                    dispatch(setBatchToUpdate({ 
                        ...response[0].data.batch,
                        compliance_id: response[0].data.batch.compliance.id,
                        media: mediaAux, 
                        registering: false
                    }));
                    setBatchIsRegistered(true);
                    onNextButtonClick();
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function saveUpdates(){

        setErrors({});

        try{
    
            let data = Helpers.prepareBatchData({
                slaughterPlant:  slaughterPlants[slaughterPlant]?
                slaughterPlants[slaughterPlant].id
                :
                null,
                processmentPlant: processmentPlants[0].id,
                animalsQuantity,
                initialPh,
                hangMethod,
                slaughterDate,
            })

            let mediaAux = mediaList;
            data.data.compliance = batch.compliance
            data.data.compliance_id = batch.compliance.id

            const today = new Date(); 
            const formattedToday = format(today, "dd/MM/yyyy");
            const formattedSlaughterDate = format(slaughterDate, "dd/MM/yyyy");
            const parsedToday = parse(formattedToday, "dd/MM/yyyy", new Date());
            const parsedSlaughterDate = parse(formattedSlaughterDate, "dd/MM/yyyy", new Date());

            if (isAfter(parsedSlaughterDate, parsedToday)) {
                setErrors({ slaughterDate: true });
                warn({
                    title:"Atenção",
                    body:"Por favor, selecione uma data de abate anterior ou igual à data de hoje.",
                    showCancelButton:false,
                    secondButton:{ label:"Entendi", color:'#A4A4A4' }
                })
                return;
            }

            // if(animalsQuantity < animals){
            //     setErrors({ animalsQuantity: true });
            //     warn({
            //         title:"Atenção",
            //         body:"A quantidade de animais declarada é menor do que o número de animais já cadastrados nesse lote.",
            //         showCancelButton:false,
            //         secondButton:{ label:"Entendi", color:'#A4A4A4' }
            //     })
            //     return;
            // }

            const isDorper = batch.races.filter((r) => r.id === 1).length > 0

            if(isDorper && mediaList.length === 0){
                setErrors({ media:true });
    
                return null
            }

            if (data.message === "Sucesso") {
                await API.put('/batches/' + batch.id, { batch: data.data });
              
                const mediaResponses = [];
              
                for (let index = 0; index < mediaList.length; index++) {
                  const media = mediaList[index];
                  let base64;
              
                  try {
                    base64 = await Helpers.URLtoBase64(media.path);
              
                    if (media.file?.name) {
                      if (batch.media_id) {
                        const response = await API.put(`sheepmedia/${batch.media_id[index]}`, {
                          sheepMedia: base64,
                          name: media.file.name,
                          type: media.file.type,
                          batchId: batch.id
                        });
                        mediaResponses.push(response);
                      } else {
                        const response = await API.post(`sheepmedia`, {
                          sheepMedia: base64,
                          name: media.file.name,
                          type: media.file.type,
                          batchId: batch.id,
                          userId: Cookies.get('sheep-user-id'),
                          companyId: Cookies.get('sheep-company-id')
                        });
                        mediaResponses.push(response);
                      }
                    }
                  } catch (error) {
                    console.error('Error processing media:', error);
                  }
                }
              
                await Promise.all(mediaResponses);
                mediaAux = mediaResponses.map(res => ({ ...res.data.sheepMedia, path: res.data?.sheep_media_url ?? null }));
    
                dispatch(setBatchToUpdate({ 
                    media: mediaAux, 
                    registering: false 
                }));

            onNextButtonClick();
            
            }else{
                
                setErrors(data.emptyValues)
                console.log('erro',data.emptyValues)
            }
            
        } catch(err){
            console.log(err)
        }

    }

    const handleMediaChange = (e) => {
        if (mediaList.length < 3) {
            const mediaPath = URL.createObjectURL(e.target.files[0]);
            const newMedia = {
                path: mediaPath,
                file: {
                    name: e.target.files[0].name,
                    type: e.target.files[0].type
                }
            };
            setMediaList([...mediaList, newMedia]);
            setErrors({media: false});
            dispatch(setBatchToUpdate({ media: [...mediaList, newMedia], registering: true }));
        } else {
            setErrors({media: true});
        }
    };
    
    const handleRemoveMedia = async (indexMedia) => {

        console.log(mediaList)
        try {
          // Encontre o item a ser deletado
          const mediaToDelete = mediaList[indexMedia];
          
          if (!mediaToDelete) {
            throw new Error('Media not found');
          }
      
          // Se o mediaToDelete tiver um id, faça a requisição de exclusão
          if (mediaToDelete.id) {
            await API.delete(`sheepmedia/${mediaToDelete.id}`);
          }

          // Atualize a lista de mídia localmente
          const newMediaList = mediaList.filter((_, i) => i !== indexMedia);
      
          // Atualize o estado
          setMediaList(newMediaList);
          setErrors({ media: false });
          dispatch(setBatchToUpdate({ media: newMediaList, registering: true }));
      
        } catch (error) {
          console.error('Error removing media:', error);
          // Aqui você pode definir um estado de erro para mostrar uma mensagem ao usuário
          setErrors({ media: true });
        }
      };

    return(
        <Container>
            <Title className="large">Informações do Lote</Title>
            <ContentContainer>
                <InputsContainer>
                    <InputDivisionLeft>
                        <InputTitle className="medium">Planta de Abate</InputTitle>
                        <FakeContainer>
                            <Select key={-1} value={slaughterPlant} onChange={(e)=>{
                                setSlaughterPlant(e.target.value)
                                dispatch(setBatchToUpdate({ slaughter_plants: slaughterPlants[e.target.value], registering:true }))
                                }} error={errors.slaughterPlant}>
                            <option value={-1}>Selecione a Planta de Abate</option>
                            {slaughterPlants.map((s, index) => (
                                <option key={index} value={index}>
                                    {s.fridge.name}
                                </option>
                            ))}
                            </Select>
                            <FakeBorder error={errors.slaughterPlant}/>
                        </FakeContainer>
                        <SmallersContainer>
                            <SmallerInput>
                                    <InputTitle className="medium">Qtd de Animais</InputTitle> 
                                    <Input value={animalsQuantity} onChange={handleAnimalsQuantity} placeholder="0" error={errors.animalsQuantity}/>
                            </SmallerInput>
                            <SmallerInput>
                                    <InputTitle className="medium">pH Inicial</InputTitle>
                                    <Input value={Helpers.formatpH(initialPh)} onChange={handleInitialPh} placeholder="0" error={errors.initialPh}/>
                            </SmallerInput>
                        </SmallersContainer>    

                    </InputDivisionLeft>
                    <InputDivisionMiddle>
                        <InputTitle className="medium">Planta de Processamento</InputTitle> 
                        <FakeContainer>
                            <Select disabled={true} value={processmentPlant} onChange={(e)=>
                                {
                                    setProcessmentPlant(e.target.value)
                                }}
                                error={errors.processmentPlant}
                                >
                                {processmentPlants.map((p, index) => (
                                    <option key={index} value={index}>
                                        {p.fridge.name}
                                    </option>
                                ))}
                            </Select>
                            <FakeBorder error={errors.processmentPlant}/>
                        </FakeContainer>
                        <BiggerContainer>
                            <BiggerInput1>
                                <InputTitle className="medium">Método de Pendura</InputTitle> 
                                <ButtonsContainer error={errors.hangMethod}>
                                    <AquilesButton 
                                        selected={isSelected} 
                                        onClick={() => {
                                            setIsSelected('Aquiles')
                                            setHangMethod('Aquiles')
                                            dispatch(setBatchToUpdate({ hang_method: 1, registering:true }))
                                        }} 
                                        className="medium">
                                            Aquiles
                                    </AquilesButton>
                                    <PelvesButton 
                                        selected={isSelected}
                                        onClick={() => {
                                            setIsSelected('Pelves')
                                            setHangMethod('Pelves')
                                            dispatch(setBatchToUpdate({ hang_method: 0, registering:true }))
                                        }} 
                                        className="medium">
                                            Pelves
                                    </PelvesButton>
                                </ButtonsContainer>
                            </BiggerInput1>
                        </BiggerContainer>
                    </InputDivisionMiddle>
                    <InputDivisionRight>
                        <InputTitle className="medium">Data de Abate</InputTitle>
                        <StyledDatePicker 
                        dateFormat="dd/MM/yyyy"
                        selected={slaughterDate}
                        onChange={handleFormateDate}
                        // onChangeRaw={handleRawChangeDate}  
                        placeholderText="Selecionar data"
                        locale={pt}
                        error={errors.slaughterDate}
                        />
                        <div style={{height:'100px'}}></div>
                    </InputDivisionRight>
                </InputsContainer>
                

                <InputTitle style={{ marginTop:30 }} className="medium">Mídia</InputTitle> 
                
                {mediaList.length > 0 ? 
                (
                
                    <div>
                            {mediaList && mediaList.map((media, index) => (
                                <div key={index} style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                                    <img 
                                        style={{ borderRadius:3, marginTop:10, maxWidth:100, maxHeight:230, marginRight:10}} 
                                        src={media.sheep_media_url ?? media.path} 
                                        alt="Mídia selecionada"
                                    />
                                    <div style={{ cursor: 'pointer', display:'flex', justifyContent:'center', alignItems: 'center', marginLeft:'1%' }} onClick={() => handleRemoveMedia(index)} >
                                    <img 
                                        style={{ width:'30px' }} 
                                        src={removeImg} 
                                        alt="Mídia selecionada"
                                    />
                                    </div>
                                </div>
                            ))}
                            {mediaList.length < 3 && (
                                <div>
                                    
                                    <label htmlFor="fileInput" style={{ cursor: 'pointer', display: 'inline-block', width: '150px', height: '40px', border: `${errors.media ? '1px solid #EE4245' : '1px solid #ccc'}`, borderRadius: '5px', textAlign: 'center', lineHeight: '40px' }}>Escolher arquivo</label>
                                    <input 
                                        id="fileInput" 
                                        style={{ display: 'none' }} 
                                        type="file" 
                                        accept="image/*,video/*" 
                                        onChange={(e)=>handleMediaChange(e)} 
                                    />
                                </div>
                            )}
                        </div>
                    ) :
                        <div>
                            
                            <label htmlFor="fileInput" style={{ cursor: 'pointer', display: 'inline-block', width: '150px', height: '40px', border: `${errors.media ? '1px solid #EE4245' : '1px solid #ccc'}`, borderRadius: '5px', textAlign: 'center', lineHeight: '40px' }}>Escolher arquivo</label>
                            <input 
                                id="fileInput" 
                                style={{ display: 'none' }} 
                                type="file" 
                                accept="image/*,video/*" 
                                onChange={(e)=>handleMediaChange(e)} 
                            />
                        </div>
                    }

                <NextContainer>
                    <NextButton className="medium" onClick={editMode?saveUpdates:saveAndNextPage}>{editMode?'Salvar':'Salvar e prosseguir'}</NextButton>
                </NextContainer>
            </ContentContainer>
        </Container>
    )
}