import React from 'react';
import { BatchesLink, Container, ExitContainer, ExitLink, List, LogoutIcon, Menus, RegisterLink, Option, ReportButton } from './sideBarStyles';
import logo from '../src/assets/images/logo.png'
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout, resetBatch } from './store/modules/batch/actions';
import { useWarn } from './context/Modal';
import { useNavigate } from 'react-router';


export function Sidebar({isSelected}){
    
    const { batch } = useSelector((state)=> state.Reducers);
    const dispatch = useDispatch();
    const {warn, closeWarn} = useWarn();
    const navigate = useNavigate();

    function cleanRedux(){
        if(batch.id){
            dispatch(resetBatch());
        }else{
        }
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    function sketchMode(){
        if(batch.registering){
        warn({
            title:"Atenção",
            body: "Um rascunho de cadastro está em andamento. O que deseja fazer?",
            firstButton:{ label:"Novo Cadastro", function:()=>{ 
                dispatch(resetBatch())
                closeWarn()
                navigate('../register')
            }, color:'#F1972E'},
            secondButton:{ label:"Continuar o rascunho", function: ()=>{
                navigate('../register')
                closeWarn()
            }, color:'#3F823E' }
        })
        }else {
            navigate('../register')
        }

    }

    return(
        <Container>
            <Menus>
                <img src={logo} width={70} alt='logo' style={{marginTop:'8px'}}/>
                <List>                
                    <BatchesLink onClick={cleanRedux} to={`../batches`} className='medium' isSelected={isSelected}>
                            Lotes
                    </BatchesLink>
                
                    <Option onClick={()=> sketchMode()} className='medium' isSelected={isSelected}>
                            {batch.id && batch.status === "CREATED"?`Lote ${batch.id}`:batch.registering?'Rascunho':'Cadastrar'}
                    </Option>   

                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <ReportButton onClick={()=>{navigate('/report')}} className='medium' isSelected={isSelected}>
                                Relatório
                        </ReportButton>              
                        <div style={{ backgroundColor:'#027845', color:'white', padding:'1px 4px', borderRadius:'12px',fontSize:'.6rem' }}>Novo</div>
                    </div>
                </List>
            </Menus>
            <ExitLink className='medium' to={`/`} onClick={handleLogout}>
                <div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <LogoutIcon/>
                </div>
                <ExitContainer>
                    Sair
                </ExitContainer>
            </ExitLink>
        </Container>
    )
}