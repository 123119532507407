import React, { useEffect, useState } from 'react';
import { Container } from './styles';

import MobileLayout from './Mobile';
import LandscapeLayout from './Landscape';
import DesktopLayout from './Desktop';

export default function Evaluation(){

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );
 
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1000);
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return(

        <Container>
          
            {(isMobile && isPortrait) ? (
                <MobileLayout/>
            ) 

            : 
                <>
                    {(isMobile && !isPortrait) ? (
                        <LandscapeLayout/>
                    )

                    :  
                        <DesktopLayout/>
                    }
                </>
            }
        </Container>
    )

}