import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import {
  FormGroup,
  FormLabel,
  FormInput,
  Title,
  SubmitButton,
  CancelButton,
  FormSelect,
  FormContainer
} from '../styles';
import Modal from '..';

const stateMasks = {
  'Acre': '99.999.999/999-99',
  'Alagoas': '99999999-9',
  'Amazonas': '99.999.999-9',
  'Amapá': '99999999-9',
  'Bahia': ['999999-99', '9999999-99'],
  'Ceará': '99999999-9',
  'Distrito Federal': '99.999.999/999-99',
  'Espírito Santo': '99999999-9',
  'Goiás': '99.999.999-9',
  'Maranhão': '99999999-9',
  'Mato Grosso': '9999999999-9',
  'Mato Grosso do Sul': '99999999-9',
  'Minas Gerais': '999.999.999/9999',
  'Pará': '99-999999-9',
  'Paraíba': '99999999-9',
  'Paraná': '99999999-99',
  'Pernambuco': '9999999-99',
  'Piauí': '99999999-9',
  'Rio de Janeiro': '99.999.99-9',
  'Rio Grande do Norte': '99.999.999-9',
  'Rio Grande do Sul': '999/9999999',
  'Rondônia': '99999999999999',
  'Roraima': '99999999-9',
  'Santa Catarina': '999.999.999',
  'São Paulo': '999.999.999.999',
  'Sergipe': '99999999-9',
  'Tocantins': ['99999999-9', '99.999.999.999'],
};

const ModalAddFarm = ({ 
  show, 
  onClose, 
  handleSubmit, 
  countries, 
  states, 
  cities, 
  getCities, 
  setSelectedState, 
  setSelectedCity,
  setFarmData,
  farmData
}) => {


  const [cnpjMask, setCnpjMask] = useState('99.999.999/9999-99');
  const [ieMask, setIeMask] = useState('');

  const handleStateChange = (value) => {
    setSelectedState(value);
    getCities(countries.find((country) => country.name === 'Brasil').eName, value);
    if (stateMasks[value]) {
      const stateMask = stateMasks[value];
      setIeMask(Array.isArray(stateMask) ? stateMask[0] : stateMask);
    }
  };

  const handleIeBlur = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    if (farmData.farmState && stateMasks[farmData.farmState]) {
      const stateMask = stateMasks[farmData.farmState];
      if (Array.isArray(stateMask)) {
        setIeMask(numericValue.length > 8 ? stateMask[1] : stateMask[0]);
      } else {
        setIeMask(stateMask);
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFarmData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  if (!show) return null;

  return (
    <Modal onClose={onClose} show={show} width={50}>
      <Title>Cadastrar Fazenda</Title>
      <form onSubmit={handleSubmit}>
        {/* nome da fazenda e do produtor */}
        <FormContainer>
          <FormGroup>
              <FormLabel htmlFor="farmName">Nome da Fazenda</FormLabel>
              <FormInput
                type="text"
                style={{width: '100%'}}
                id="farmName"
                value={farmData.farmName}
                onChange={handleChange}
                placeholder="Digite o nome da fazenda"
                required
              />
          </FormGroup>
          <FormGroup>
              <FormLabel htmlFor="productor">Produtor</FormLabel>
              <FormSelect
                id="productor"
                style={{width:'100%'}}
                value={farmData.productor.user.name}
                onChange={handleChange}
                required
                disabled
              >
                    <option key={farmData.productor.id} value={farmData.productor.user.name}>
                      {farmData.productor.user.name}
                    </option>
              </FormSelect>
          </FormGroup>
        </FormContainer>
        {/* endereço da fazenda */}
        <FormContainer>
          <FormGroup>
            <FormLabel htmlFor="farmCountry">País</FormLabel>
            <FormSelect
              id="farmCountry"
              style={{width:'100%'}}
              value={farmData.farmCountry}
              onChange={handleChange}
              required
              disabled
            >
              {countries.map((country, index) => (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="farmState">Estado</FormLabel>
            <FormSelect
              id="farmState"
              style={{width:'100%'}}
              value={farmData.farmState}
              onChange={(e) => {
                handleChange(e);
                handleStateChange(e.target.value);
              }}
              required
            >
              <option key={'-1'} value={''}>Selecione</option>
              {states.map((state, index) => (
                <option key={index} value={state.originalName}>
                  {state.name}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
        </FormContainer>
        <FormContainer>
          <FormGroup>
            <FormLabel htmlFor="farmCity">Cidade</FormLabel>
            <FormSelect
              id="farmCity"
              value={farmData.farmCity}
              onChange={(e) => {
                handleChange(e);
                setSelectedCity(e.target.value);
              }}
              required
            >
              <option key={'-1'} value={''}>Selecione</option>
              {cities.map((city, index) => (
                <option key={index} value={city.name}>
                  {city.name}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="farmStreet">Logradouro</FormLabel>
            <FormInput
              type="text"
              id="farmStreet"
              value={farmData.farmStreet}
              onChange={handleChange}
              placeholder="Digite o Logradouro"
            />
          </FormGroup>
        </FormContainer>
        
          <FormGroup style={{width:'99%'}} >
            <FormLabel htmlFor="farmCEP">CEP</FormLabel>
            <InputMask
              mask="99999-999"
              value={farmData.farmCEP}
              onChange={handleChange}
              placeholder="Digite o CEP"
              className="form-input"
              id="farmCEP"
            >
              {(inputProps) => <FormInput {...inputProps} />}
            </InputMask>
          </FormGroup>
        
        <FormContainer>
          <FormGroup>
            <FormLabel htmlFor="farmCnpj">CNPJ</FormLabel>
            <InputMask
              mask={cnpjMask}
              value={farmData.farmCnpj}
              onChange={handleChange}
              placeholder="Digite o CNPJ"
              className="form-input"
              id="farmCnpj"
            >
              {(inputProps) => <FormInput {...inputProps} />}
            </InputMask>
          </FormGroup>
          <FormGroup>
              <FormLabel htmlFor="farmIe">IE</FormLabel>
              <InputMask
                mask={ieMask}
                value={farmData.farmIe}
                onBlur={handleIeBlur}
                onChange={handleChange}
                placeholder="Digite a IE"
                className="form-input"
                id="farmIe"
                disabled={!farmData.farmState}
              >
                {(inputProps) => <FormInput {...inputProps} />}
              </InputMask>
          </FormGroup>
        </FormContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2%' }}>
          <CancelButton type="button" onClick={onClose}>Cancelar</CancelButton>
          <SubmitButton type="submit">Cadastrar</SubmitButton>
        </div>
      </form>
    </Modal>
  );
};

export default ModalAddFarm;
