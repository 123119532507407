import styled from "styled-components";
import { Link } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";

export const Container = styled.div `
    width:7%;
    height:100%;
    background-color: #275224;
    align-items:center;
    display:flex;
    position:fixed;
    flex-direction:column;
`

export const Menus = styled.div`
height:95%;
display:flex;
flex-direction:column;
align-items:center;
`

export const List = styled.div `
    display:flex;
    flex-direction:column;
    margin-top:20px;
    text-align:left;
`

export const BatchesLink = styled(Link)`
    margin-bottom:10px;
    text-decoration-line:none;
    color:#FFF;
    font-size:18px;
    background-color: ${props => props.isSelected === 'batches' ? '#00000030' : ''};
    border-radius: ${props => props.isSelected === 'batches' ? '10px' : ''};
    display: inline-block;
    padding:5px 10px;
    transition:0.2s ease-in-out;
        
    @media screen and (max-width:1400px){
        font-size:12px;
    }

    @media screen and (max-width:1600px){
        font-size:14px;
    }
`

export const RegisterLink = styled(Link)`
    margin-bottom:10px;
    text-decoration-line:none;
    color:#FFF;
    font-size:18px;
    background-color: ${props => props.isSelected === 'register' ? '#00000030' : ''};
    border-radius: ${props => props.isSelected === 'register' ? '10px' : ''};
    display: inline-block;
    padding:5px 10px;
    transition:0.2s ease-in-out;

    @media screen and (max-width:1600px){
        font-size:14px;
    }

    @media screen and (max-width:1400px){
        font-size:12px;
    }
`

export const Option = styled.div`

margin-bottom:10px;
    text-decoration-line:none;
    color:#FFF;
    font-size:18px;
    background-color: ${props => props.isSelected === 'register' ? '#00000030' : ''};
    border-radius: ${props => props.isSelected === 'register' ? '10px' : ''};
    display: inline-block;
    padding:5px 10px;
    transition:0.2s ease-in-out;

    @media screen and (max-width:1600px){
        font-size:14px;
    }

    @media screen and (max-width:1400px){
        font-size:12px;
    }

    cursor: pointer;
`

export const ReportButton = styled.div`

    text-decoration-line:none;
    color:#FFF;
    font-size:18px;
    background-color: ${props => props.isSelected === 'report' ? '#00000030' : ''};
    border-radius: ${props => props.isSelected === 'report' ? '10px' : ''};
    display: inline-block;
    padding:5px 10px;
    transition:0.2s ease-in-out;
    text-align:center;

    @media screen and (max-width:1600px){
        font-size:14px;
    }

    @media screen and (max-width:1400px){
        font-size:12px;
    }

    cursor: pointer;
`

export const ExitLink = styled(Link)`
    display:flex;
    align-items:center;
    justify-content:center;
    height:30px;
    text-decoration-line:none;
    color:#FFF;
    font-size:18px;

    @media screen and (max-width:1600px){
        font-size:16px;
    }

    @media screen and (max-width:1400px){
        font-size:14px;
    }
`

export const ExitContainer = styled.div`
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding-left:5px;
    margin-right:5px;
`

export const LogoutIcon = styled(IoIosLogOut)`
    font-size:20px;
    color:#FFF;

    @media screen and (max-width:1600px){
        font-size:18px;
    }

    @media screen and (max-width:1400px){
        font-size:16px;
    }
`