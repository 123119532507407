import styled from "styled-components";


export const Container = styled.div`
    display:flex;
    font-size: 12px;
    width:400px;
    background-color:rgba(255, 255, 255);
    margin-bottom:180px;
    border-radius:20px;
    padding:10px;
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items:center;
    justify-content:center;
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    z-index: 5;
`

export const ContentContainer = styled.div`
      width:100%;
      height:100%;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
`

export const PrimaryButton = styled.button`
    width:100px;
    height:40px;
    border-radius:3px;
    color:#000;
    cursor:pointer;

    border: 1px solid ${ p=> p.color??'#D8190f'};
    background-color:${ p=> p.color??'#D8190f'};
`

export const SecondaryButton = styled.button`
    width:100px;
    height:40px;
    border: 1px solid ${ p=> p.color??'#D8190f'};
    border-radius:2px;
    color:#FFF;
    cursor:pointer;
    background-color:${ p=> p.color??'#D8190f'};
`

export const ModalHeader = styled.div`
    font-size: 18px;
    text-align: center;
    padding: 15px 5px;
    color:#000;
`
export const ModalContent = styled.div`
    text-align: center;
    color:#000;
    font-size:15px;
`

export const ModalActions = styled.div`
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    display:flex;
    align-items:center;
    justify-content:center;
`
