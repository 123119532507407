import React, { useEffect, useState, useRef} from 'react';
import { useParams } from 'react-router';
import { AnimalContainer, AnimalContent, AnimalText, AnimalTitle, BackButtonContainer, Button, ButtonContainer, ButtonText, ButtonValue, CancelButton, CaveatContainer, CaveatText, CaveatTitle, ClassificationContainer,
        DropdownContainer, DropdownContent, DropdownContentContainer, DropdownHeader, FinishButton, FinishButtonContainer, GridButtom, GridButtonsContainer, GridButtonsPhGrContainer,
         GridColumn, GridInput, GridTitle, IdContent, InfoIcon, InfoModal, InfoModalContainer, ModalActions, ModalContent, ModalHeader, ModalItensContainer, ModalTextContainer, ModalTitle, StarIcon, StatusContainer, StatusText, StyledArrowIcon, StyledBackArrowIcon, SubtitleContainer, SubtitleText, SubtitleTitle,
        TextContainer, Title, TitleContainer, TitleInfo } from '../styles';
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { FaCircleCheck, FaCircleInfo, FaCircleXmark } from "react-icons/fa6";
import API from '../../../services/API/index';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import { useWarn } from '../../../context/Modal';


// REDUX
import {useSelector} from 'react-redux';
import ModalEvaluations from '../modal';
import Helpers from '../../../services/helpers';


export default function LandscapeLayout(){

let {id} = useParams();
    const navigate = useNavigate();

    const [selectedAnimalId, setSelectedAnimalId] = useState(null);
    const [grValues, setGrValues] = useState({});
    const [phValues, setPhValues] = useState({});
    const [estadoBotoes, setEstadoBotoes] = useState({});
    const [animalList, setAnimalList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [batchId, setBatchId] = useState();
    const [open, setOpen] = useState(false);
    const [currentAnimal, setCurrentAnimal] = useState({});
    const [otherPhValue, setOtherPhValue] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(null);
    const [header, setHeader] = useState({ batch:'', date:'', productor:''})
    const {warn, closeWarn} = useWarn();

    const modalRef = useRef();

    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );
    
    const technicianId = Cookie.get('sheep-technician');

    const { batch } = useSelector(state => state.Reducers);

    
    useEffect(() => {
        const urlParams =  new URLSearchParams(window.location.search);
        setBatchId(parseInt(urlParams.get("id")));
        setHeader({
            batch: urlParams.get("id"),
            date : urlParams.get("date"),
            productor: urlParams.get("productor")
        })
    }, []);


    const saveToLocalStorage = (data) => {
        localStorage.setItem('batchState', JSON.stringify(data));
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            saveToLocalStorage(batchId);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [batchId]);

    

    async function AnimalsList() {
        if(batchId){
            await API.get(`animalsFromBatch/${batchId}`) 
            .then((res) => {

                const createdAnimals = res.data.filter(animal => animal.evaluations.status === 'CREATED' || Object.keys(animal.evaluations).length === 0).sort((a, b) => a.animal.carcass_id - b.animal.carcass_id);

                const otherAnimals =  res.data.filter(animal => (Object.keys(animal.evaluations).length !== 0 && animal.evaluations.status !== 'CREATED')).sort((a, b) => a.animal.carcass_id - b.animal.carcass_id);
    
                const sortedAnimalList = [...createdAnimals, ...otherAnimals];
                const animalList = sortedAnimalList.filter(((animals) => animals.animal.status !== 'DELETED'))
                    
                setAnimalList(animalList);
                setLoading(false);
            })
            .catch((error) => {
                console.log('err',error);
            });
        } else{
            await API.get(`animalsFromBatch/${batch.id}`) 
                .then((res) => {
                   
    
                    const createdAnimals = res.data.filter(animal => animal.evaluations.status === 'CREATED' || Object.keys(animal.evaluations).length === 0).sort((a,b)=> a.animal.carcass_id - b.animal.carcass_id)

                    const otherAnimals =  res.data.filter(animal => (Object.keys(animal.evaluations).length !== 0 && animal.evaluations.status !== 'CREATED')).sort((a, b) => a.animal.carcass_id - b.animal.carcass_id);
        
                    const sortedAnimalList = [...createdAnimals, ...otherAnimals];
                    const animalList = sortedAnimalList.filter(((animals) => animals.animal.status !== 'DELETED'))
                    
                    setAnimalList(animalList);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('error',error);
                });
        }
    }

     const sendUpdate = async (id, name, value) =>{
        let obj = {
            animal_id:id,
            technician_id:technicianId
        }
        if (name === 'pH' && typeof value === 'string') {
            obj[name] = parseFloat(value.replace(',', '.'));
        } else {
            obj[name] = value;
        }

        await API.post('updateAnimal', obj)
        .then(()=>{
            let  updatedList = [...animalList].map((a)=>({...a}));
            let index = null;

            updatedList.forEach((item,i)=>{
                if(item.animal.id === obj.animal_id){
                    index = i;
                }   
            })
            if(!updatedList[index].evaluations.status){
                updatedList[index].evaluations.status = "CREATED";
                updatedList[index].evaluations.color = 'yellow';
                updatedList[index].evaluations[name] = value
                setAnimalList(updatedList);
            }
        })
        
     }

    useEffect(() => {
        AnimalsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, batchId]);
    
    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        if (!loading) {
        const phValue = {
            otherPhSelected:'Outro',
        };

        animalList.forEach(data => {
                    
        phValue[data.animal.id] = {
            otherPhSelected: data.evaluations.pH && (data.evaluations.pH < 5.3 || data.evaluations.pH > 5.7) ? data.evaluations.pH : 'Outro',
        };
        
        });
        setOtherPhValue(phValue);
        }
      }, [animalList, loading]);


      useEffect(() => {
        if (!loading) {
        const novoEstadoBotoes = {
            grSelected:'',
            phSelected:'',
            marblingSelected:'',
            temperatureSelected:''
        };

        animalList.forEach(data => {
        novoEstadoBotoes[data.animal.id] = {
            phSelected: data.evaluations.pH ? data.evaluations.pH : '',
            grSelected: data.evaluations.fat_thickness !== null && data.evaluations.fat_thickness !== ''? parseInt(data.evaluations.fat_thickness) : '',
            marblingSelected: data.evaluations.meat_marbling ? data.evaluations.meat_marbling : '',
            temperatureSelected: data.evaluations.temperature  !== null && data.evaluations.temperature !== ''? data.evaluations.temperature : ''
        };
        
        if(novoEstadoBotoes[data.animal.id].grSelected !== null){
            setGrValues(prevGrValues => ({
                ...prevGrValues,
                [data.animal.id]: novoEstadoBotoes[data.animal.id].grSelected
            })); 
        }

        });
        setEstadoBotoes(novoEstadoBotoes);
        }
      }, [animalList, loading]);

      const updateAnimal = async (animal) => {

        
        let update = {"animals":
        [
            {
                "animal":{
                    "id":animal.animal.id,
                    "status": animal.animal.status,
                    "carcass_id": animal.animal.carcass_id,
                    "sex": animal.animal.sex,
                    "dentition": animal.animal.dentition,
                    "carcass_weight": animal.animal.carcass_weight,
                    "initial_pH": animal.animal.initial_pH,
                    "initial_temp": animal.animal.initial_temp,
                    "earring": animal.animal.earring,
                    "sheep": animal.animal.sheep,
                    "creation_date": animal.animal.creation_date,
                    "batch_id": animal.animal.batch_id,
                    "supplier_id": animal.animal.supplier_id
                },
                "evaluation":{
                    "animal_id": animal.animal.id,
                    "fat_thickness":estadoBotoes[animal.animal.id].grSelected !== '' ? estadoBotoes[animal.animal.id].grSelected : -1,
                    "pH":estadoBotoes[animal.animal.id].phSelected ? estadoBotoes[animal.animal.id].phSelected : -1,
                    "temperature": estadoBotoes[animal.animal.id].temperatureSelected !== undefined && estadoBotoes[animal.animal.id].temperatureSelected !== ''? estadoBotoes[animal.animal.id].temperatureSelected : -1,
                    "meat_marbling": estadoBotoes[animal.animal.id].marblingSelected ? estadoBotoes[animal.animal.id].marblingSelected : -1,
                    "technician_id": 1
                }

            }
        ]}
        setSelectedAnimalId('');

        await API.post('updateAllAnimals', update).then(
            (response) => {
                AnimalsList();
            }
          ).catch((error) => {
              console.log(error.message);
            })
    }

    const handleCloseModal = (e) => {
        setOpen(false)
    }

    const handleUpdateAnimal = () => {
        if(currentAnimal){
            updateAnimal(currentAnimal);
            setOpen(false);
        }
    };

    const handleClick = (id) => {
        setSelectedAnimalId(id === selectedAnimalId ? null : id);
    };

    const handleSquareClick = (e) => {
        e.stopPropagation();
    };


      const renderGrNumberButtons = (animalId) => {
        const currentGrValue = (grValues[animalId] !== undefined && grValues[animalId] !== null && typeof grValues[animalId] === 'number' && !isNaN(grValues[animalId])) ? grValues[animalId] : 6;
        const buttons = [];
        for (let i = currentGrValue ; i <= currentGrValue + 3; i++) {
            
            if(i >= 0){

                buttons.push(
                    <GridButtom
                        portrait={isPortrait}
                        key={i}
                        onClick={(e) => {
                            handleSquareClick(e);
                            if(i >= 0 && i <= 60){
                                handleGrButtonClick(animalId, i)
                                sendUpdate(animalId, 'fat_thickness', i);
                            }
                        }}
                        style={{
                            backgroundColor: estadoBotoes[animalId]?.grSelected === i ? '#275224' : i >= 0 && i <= 60 ? '#D9D9D9': '' ,
                            color: estadoBotoes[animalId]?.grSelected === i ? '#FFF' : '#000',
                            cursor: i >= 0 && i <= 60 ? 'pointer' : 'initial'
                        }}
                    >
                        <ButtonValue portrait={isPortrait} className='medium'>{i >= 0 && i <= 60 ? i : ''}</ButtonValue>
                    </GridButtom>
                );
            }
        }
       
        return buttons;
    };

    const handleMinusClick = (animalId) => {
        const currentGrValue = (grValues[animalId] !== undefined && grValues[animalId] !== null && typeof grValues[animalId] === 'number' && !isNaN(grValues[animalId])) ? grValues[animalId] : 6;
        let newGrValue = currentGrValue - 4;
        if (newGrValue < 0) {
            newGrValue = 0;
        }
        setGrValues(prevGrValues => ({
            ...prevGrValues,
            [animalId]: newGrValue
        }));
    };

    const handlePlusClick = (animalId) => {
        const currentGrValue = (grValues[animalId] !== undefined && grValues[animalId] !== null && typeof grValues[animalId] === 'number' && !isNaN(grValues[animalId])) ? grValues[animalId] : 6;
        const newGrValue = Math.min(currentGrValue + 4, 60);
        setGrValues(prevGrValues => ({
            ...prevGrValues,
            [animalId]: newGrValue
        }));
    };

    const renderPhNumberButtons = (animalId) => {
        const currentPhValue = (phValues[animalId] !== undefined && phValues[animalId] !== null && typeof phValues[animalId] === 'number' && !isNaN(phValues[animalId])) ? phValues[animalId] : 5.5;
        const buttons = [];
        const minValue = 5.2;
        const maxValue = 7;
        const increment = 0.1;


        for (let i = currentPhValue; i <= currentPhValue + 3 * increment; i += increment) {
            const isWithinRange = (i >= minValue && i <= maxValue);
            if(i<=7){
                buttons.push(
                    <GridButtom
                        portrait={isPortrait}
                        key={i}
                        onClick={(e) => {
                            handleSquareClick(e);
                            if (isWithinRange) {
                                handlePhButtonClick(animalId, (Math.ceil(i * 10) / 10));
                                sendUpdate(animalId, 'pH', (Math.ceil(i * 10) / 10));
                            }
                        }}
                        style={{
                            backgroundColor: parseFloat(estadoBotoes[animalId]?.phSelected) === (Math.ceil(i * 10) / 10) ? '#275224' : (isWithinRange ? '#D9D9D9' : '#F0F0F0'),
                            color: parseFloat(estadoBotoes[animalId]?.phSelected) === (Math.ceil(i * 10) / 10) ? '#FFF' : '#000',
                            cursor: isWithinRange ? 'pointer' : 'initial',
                        }}
                    >
                        <ButtonValue portrait={isPortrait} className='medium'>{isWithinRange ? i.toFixed(1).replace('.',',') : ''}</ButtonValue>
                    </GridButtom>
                );
            }
        }
        return buttons;
    };

    const handlePhMinusClick = (animalId) => {
        const currentPhValue = (phValues[animalId] !== undefined && phValues[animalId] !== null && typeof phValues[animalId] === 'number' && !isNaN(phValues[animalId])) ? phValues[animalId] : 5.5;
        let newPhValue = currentPhValue - 0.4;
        if (newPhValue < 5.2) {
            newPhValue = 5.2;
        }
        setPhValues(prevPhValues => ({
            ...prevPhValues,
            [animalId]: newPhValue
        }));
    };
    

    const handlePhPlusClick = (animalId) => {
        const currentPhValue = (phValues[animalId] !== undefined && phValues[animalId] !== null && typeof phValues[animalId] === 'number' && !isNaN(phValues[animalId])) ? phValues[animalId] : 5.5;
        let newPhValue = currentPhValue + 0.4;
        if (newPhValue >= 7) {
            newPhValue = 6.7;
        }
        newPhValue = Math.round(newPhValue * 10) / 10;
        setPhValues(prevPhValues => ({
            ...prevPhValues,
            [animalId]: newPhValue
        }));
    };
    
    const handlePhButtonClick = (animalId, value) => {
            setEstadoBotoes(prevState => ({
              ...prevState,
              [animalId]: {
                ...prevState[animalId],
                phSelected:value,
              },
            }));
    };

    const handleGrButtonClick = (animalId, value) => {
        setEstadoBotoes(prevState => ({
            ...prevState,
            [animalId]: {
                ...prevState[animalId],
                grSelected: value,
            },
        }));
    };

    const handleMarblingButtonClick = (animalId, value) => {
        setEstadoBotoes(prevState => ({
            ...prevState,
            [animalId]: {
              ...prevState[animalId],
              marblingSelected: value,
            },
          }));
    };

    const handleTemperatureButtonClick = (animalId, value) => {
        setEstadoBotoes(prevState => ({
            ...prevState,
            [animalId]: {
              ...prevState[animalId],
              temperatureSelected:value
            },
          }));
    };

    const handleInputChange = (e, animalId) => {
        let newValue = e.target.value.trim();  
        newValue = newValue.replace(/[^0-9.,]+/g, ''); 
    
        const decimalCount = (newValue.match(/[.,]/g) || []).length;
        newValue = newValue.replace(/[.,]/g, (match, offset) => {
            
            if (offset !== newValue.lastIndexOf(match) || decimalCount > 1) {
                return '';
            }
            return match;
        });
    
        newValue = newValue.replace(/(\d)(?=\d)/g, '$1.');
    
        const regex = /^[0-9]{0,2}[.,]?[0-9]?$/;
        if (!newValue || regex.test(newValue)) {
      
            newValue = newValue.replace(',', '.'); 
        } else {
            newValue = '';
        }

        setEstadoBotoes(prevState => ({
            ...prevState,
            [animalId]: {
                ...prevState[animalId],
                phSelected: newValue
            }
        }));
    
        setOtherPhValue(prevState => ({
            ...prevState,
            [animalId]: {
                ...prevState[animalId],
                otherPhSelected: newValue
            }
        }));
    };
    

    const handleInputClick = (e) => {
        setIsEditing(true);
        e.target.value = '';
    };

    const handleInfoModalOpen = (status, index) => {
        if(status === 'FINISHED' || status === 'INAPT'){
            setInfoModalOpen(index)
        }
    }

    const handleInfoModalClose = (e) => {
        if(modalRef.current && !modalRef.current.contains(e.target))
        setInfoModalOpen(false)
    }

    const handleInputBlur = (e, animalId, value) => {
        setIsEditing(false);
        handlePhButtonClick(animalId, value)
        sendUpdate(animalId, 'pH', value)
    };

    const temperatureTransformation = (t) => {
        if((t !== null && t !== undefined && t !== '') && t !== -1){

                if(t === 0)
                return '0 ~ 3'
                if(t === 1)
                return '3,1 ~ 6'
                if(t === 2)
                return '6,1 ~ 9'
                if(t === 3)
                return '9,1 ~ 12'
                if(t === 4)
                return '> 12'            
            }
            else return '-'
    }
    
    const handleOpenModal = (animal) => {
        setCurrentAnimal(animal);
        setOpen(true);
    };

    const softDeleteAnimal = async (animalId, carcassId) =>{
        warn({
        title:'Atenção!',
        body: <p style={{fontSize:'15px'}}>Você deseja mesmo deletar o animal {carcassId} ?</p>,
        showCancelButton:true,
        size:'180',
        secondButton:{label:'Excluir',
        color:'#d25650',function: async ()=>{
            await API.post('softDeleteAnimal/'+animalId, {batchId:batchId}).then((res)=>{
                
                if(res.status == 200){
                    
                    setAnimalList(animalList.filter((animals) => animals.animal.id!== animalId));
                    closeWarn()
                }
            }).catch((error)=>{console.log('error on soft delete', error)})
        }}})
    }

    if (loading) {
        return <div>Carregando...</div>;
    }


    return(

                <>

                    <ModalEvaluations
                    animal={currentAnimal}
                    handleCloseModal={handleCloseModal} 
                    setOpen={setOpen}
                    open={open}
                    handleUpdateAnimal={handleUpdateAnimal}
                    />

                     <TitleContainer>
                         <TitleInfo>
                             <BackButtonContainer>
                                 <StyledBackArrowIcon color='#275224' onClick={()=>{navigate(-1)}}/>
                             </BackButtonContainer>
                             <Title className='large'>Avaliação</Title>
                             <Title/>
                         </TitleInfo>    
                         <div style={{display:'flex', width:'100%', justifyContent:'space-evenly', marginBottom:'10px'}}>
                             <SubtitleContainer>
                                 <SubtitleTitle className='medium'>Lote</SubtitleTitle>
                                 <SubtitleText className='medium'>{header.batch}</SubtitleText>
                             </SubtitleContainer>
                             <SubtitleContainer>
                                 <SubtitleTitle className='medium'>Abate</SubtitleTitle>
                                 <SubtitleText className='medium'>{header.date}</SubtitleText>
                             </SubtitleContainer>
                             <SubtitleContainer>
                                 <SubtitleTitle className='medium'>Produtor</SubtitleTitle>
                                 <SubtitleText className='medium'>{header.productor}</SubtitleText>
                             </SubtitleContainer>
                         </div>
                     </TitleContainer>
                     {animalList.map((a, index)=>(
                        <>
                        {infoModalOpen === index && 
                            (
                                <InfoModalContainer onClick={handleInfoModalClose} key={index}>
                                    <InfoModal ref={modalRef} portrait={isPortrait}>
                                        <ModalItensContainer>
                                            <div style={{display:'flex', flexDirection:'row', width:'45%', justifyContent:'space-around', alignItems:'center', marginBottom:'10px'}}>
                                                <InfoIcon/>
                                                <ModalHeader>Informações do animal</ModalHeader>
                                            </div>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-around'}}>
                                                <div>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>ID: </ModalTitle>
                                                        <ModalContent>{a.animal.carcass_id}</ModalContent>
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>pH Inicial: </ModalTitle>
                                                        <ModalContent>{a.animal.initial_pH && a.animal.initial_pH !== -1 ? a.animal.initial_pH.toFixed(1).replace('.',',') : '-'}</ModalContent>
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>Temp. I.: </ModalTitle>
                                                    <ModalContent>{Helpers.VerifyTemperatureInterval(a.animal.initial_temp)}</ModalContent>    
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>Dentição: </ModalTitle>
                                                        <ModalContent>{a.animal.dentition}</ModalContent>
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>PCQ (kg): </ModalTitle>
                                                        <ModalContent>{a.animal.carcass_weight?.toLocaleString('pt-BR') ? a.animal.carcass_weight?.toLocaleString('pt-BR') : '-'}</ModalContent>
                                                    </ModalTextContainer>       
                                                </div>
                                                <div>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>Sexo:</ModalTitle>
                                                    <ModalContent>{a.animal.sex === 'femea' ? 'fêmea' : a.animal.sex}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>pH Final: </ModalTitle>
                                                    <ModalContent>{a.evaluations.pH && a.evaluations.pH !== -1 ? a.evaluations.pH.toFixed(1).replace('.',',') : '-'}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>Temp. F.: </ModalTitle>
                                                    <ModalContent>{temperatureTransformation(a.evaluations.temperature)}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>Marmoreio: </ModalTitle>
                                                    <ModalContent>{a.evaluations.meat_marbling && a.evaluations.meat_marbling !== -1 ? a.evaluations.meat_marbling : '-'}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>GR(mm): </ModalTitle>
                                                    <ModalContent>{a.evaluations.fat_thickness !== undefined && a.evaluations.fat_thickness !== null && a.evaluations.fat_thickness !== -1 && a.evaluations.fat_thickness !== '' ? a.evaluations.fat_thickness : '-'}</ModalContent>
                                                </ModalTextContainer>
                                                </div>
                                            </div>
                                            <ModalActions>
                                                <CancelButton onClick={() => handleInfoModalClose(a)}>Fechar</CancelButton>
                                            </ModalActions>
                                        </ModalItensContainer>
                                    </InfoModal>
                                </InfoModalContainer>
                            )}
                         <AnimalContainer
                         key={a.animal.id}
                         status={a.evaluations.status}
                         statusColor={a.evaluations.color}
                         evaluationLength={Object.keys(a.evaluations).length}
                         isselected={a.animal.id === selectedAnimalId}
                         onClick={() => handleInfoModalOpen(a.evaluations?.status, index)}>
                             <DropdownContainer isselected={a.animal.id === selectedAnimalId} >
                                 <DropdownHeader onClick={() => a.evaluations.status === 'CREATED' || Object.keys(a.evaluations).length === 0 ? handleClick(a.animal.id):''} dorper={a.animal.dorper}>
                                     <div style={{display:'flex', height:'100%', width:'100%', justifyContent:'space-around', margin:'10px 0 30px 0'}}>
                                         <IdContent isselected={a.animal.id=== selectedAnimalId} status={a.evaluations.status} statusColor={a.evaluations.color}>
                                            <AnimalTitle style={{ fontSize:'20px', marginBottom:'2px'}}>{a.animal.carcass_id}</AnimalTitle>
                                         </IdContent>
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>pH Final</AnimalTitle>
                                             <AnimalText className='medium'>{a.evaluations.pH && a.evaluations.pH !== -1 ? a.evaluations.pH.toFixed(1).replace('.',',') : '-'}</AnimalText>
                                         </AnimalContent>
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>Temp. Final</AnimalTitle>
                                             <AnimalText className='medium'>{temperatureTransformation(a.evaluations.temperature)}</AnimalText>
                                         </AnimalContent>    
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>Marmoreio</AnimalTitle>
                                             <AnimalText className='medium'>{a.evaluations.meat_marbling && a.evaluations.meat_marbling !== -1 ? a.evaluations.meat_marbling : '-'}</AnimalText>
                                         </AnimalContent >
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>GR(mm)</AnimalTitle>
                                             <AnimalText className='medium'>{a.evaluations.fat_thickness !== undefined && a.evaluations.fat_thickness !== null && a.evaluations.fat_thickness !== -1 && a.evaluations.fat_thickness !== '' ? a.evaluations.fat_thickness : '-'}</AnimalText>
                                         </AnimalContent >                           
                                     </div>
                                     {/* <div style={{display:'flex', height:'100%', width:'100%', justifyContent:'space-around', marginBottom:'10px'}}>
                                        <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                            <AnimalTitle className='medium'>Sexo</AnimalTitle>
                                            <AnimalText className='medium'>{a.animal.sex === 'femea' ? 'fêmea' : a.animal.sex}</AnimalText>
                                        </AnimalContent>
                                        <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                            <AnimalTitle className='medium'>pH Inicial</AnimalTitle>
                                            <AnimalText className='medium'>{a.animal.initial_pH && a.animal.initial_pH !== -1 ? a.animal.initial_pH.toFixed(1).replace('.',',') : '-'}</AnimalText>
                                        </AnimalContent>
                                        <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                            <AnimalTitle className='medium'>Temp. Inicial</AnimalTitle>
                                            <AnimalText className='medium'>{Helpers.VerifyTemperatureInterval(a.animal.initial_temp)}</AnimalText>
                                        </AnimalContent>
                                        <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                            <AnimalTitle className='medium'>Dentição</AnimalTitle>
                                            <AnimalText className='medium'>{a.animal.dentition}</AnimalText>
                                        </AnimalContent >
                                        <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                            <AnimalTitle className='medium'>PCQ</AnimalTitle>
                                            <AnimalText className='medium'>{a.animal.carcass_weight?.toLocaleString('pt-BR')}</AnimalText>
                                        </AnimalContent>                                                           
                                     </div> */}
                                    
                                     {a.evaluations.color === 'blue' ? (
                                     <CaveatContainer isselected={a.animal.id === selectedAnimalId}>
                                             <>
                                                 <CaveatTitle className='medium'>Cortes aproveitados:</CaveatTitle>
                                                 <CaveatText className='medium'>{a.evaluations.cutting_meat === 0 ? 'Lombo, file mignon, picanha e alcatra' : 'picanha e alcatra'}</CaveatText>
                                             </>
                                     </CaveatContainer>
                                         ) : ''}
 
                                     <div style={{display:'flex', width:'100%', marginBottom:'10px', justifyContent:'space-around', marginLeft:'20px'}}>
                                         <StatusContainer status={a.evaluations.status} statusColor={a.evaluations.color} isselected={a.animal.id === selectedAnimalId}>
                                             {Object.keys(a.evaluations).length === 0 ? (
                                                 <>
                                                     <PiDotsThreeCircleFill size={25} color='#606773'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Pendente</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ) : a.evaluations.status === 'CREATED' ? (
                                                 <>
                                                     <PiDotsThreeCircleFill size={25} color='#F1C62E'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Andamento</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'blue' ? (
                                                 <>
                                                     <FaCircleCheck size={20} color='#4D7EC6'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Aprovado com Ressalvas</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'orange' ? (
                                                 <>
                                                     <FaCircleCheck size={20} color='#F1C62E'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Aprovado</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'green' ? (
                                                 <>
                                                     <FaCircleCheck size={20} color='#275224'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Aprovado</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'red' ?(
                                                 <>
                                                     <FaCircleXmark size={20} color='#C93131'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Desclassificado</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): ''
                                             }
                                         </StatusContainer>
                                        <ClassificationContainer style={{width:'250px'}}/>
                                        <ClassificationContainer isselected={a.animal.id === selectedAnimalId} portrait={isPortrait}>
                                            {a.evaluations.stars == 4 ? (
                                            <>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                            </>
                                            ) : a.evaluations.stars == 5 ? (
                                            <>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                            </>
                                            ) : Object.keys(a.evaluations).length === 0 ? <StyledArrowIcon color='#575757' isselected={a.animal.id === selectedAnimalId}/> : 
                                                a.evaluations.status === 'CREATED' ? <StyledArrowIcon color='#F1C62E' isselected={a.animal.id === selectedAnimalId}/> : ''}
                                        </ClassificationContainer>
                                     </div>
                                 </DropdownHeader>
                                 <DropdownContentContainer isselected={a.animal.id === selectedAnimalId}>
                                     
                                 <DropdownContent portrait={isPortrait}>
                                         <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                             <GridTitle className='medium'>Temperatura</GridTitle>
                                             <GridButtonsContainer portrait={isPortrait}>
                                                <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 0)
                                                     sendUpdate(a.animal.id, 'temperature', 0)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 0 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 0 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>0 ~ 3</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 1)
                                                     sendUpdate(a.animal.id, 'temperature', 1)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 1 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 1 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>3,1 ~ 6</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 2)
                                                     sendUpdate(a.animal.id, 'temperature', 2)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 2 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 2 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>6,1 ~ 9</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 3)
                                                     sendUpdate(a.animal.id, 'temperature', 3)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 3 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 3 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>9,1 ~ 12</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 4)
                                                     sendUpdate(a.animal.id, 'temperature', 4)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 4 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 4 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>{'> 12'}</ButtonValue>
                                                 </GridButtom>
                                             </GridButtonsContainer>
                                         </GridColumn>
                                     
                                             <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                                 <GridTitle className='medium'>Marmoreio</GridTitle>
                                                 <GridButtonsContainer portrait={isPortrait}>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 1)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 1)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 1 ? '#275224' : '#D9D9D9',
                                                                 color:estadoBotoes[a.animal.id]?.marblingSelected === 1 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>1</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Ausente</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 2)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 2)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 2 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 2 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>2</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Pouco</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 3)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 3)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 3 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 3 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>3</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Moderado</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 4)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 4)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 4 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 4 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>4</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Elevado</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 5)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 5)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 5 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 5 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>5</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Abundante</ButtonText>
                                                     </GridButtom>
                                                 </GridButtonsContainer>
                                             </GridColumn>
                                    </DropdownContent>
 
                                    <DropdownContent portrait={isPortrait}>
                                    <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                                    <GridTitle className='medium'>pH</GridTitle>
                                                    <GridButtonsPhGrContainer>
                                                        <GridButtom onClick={(e) => {
                                                            handleSquareClick(e)
                                                            handlePhMinusClick(a.animal.id)}}>
                                                            <ButtonValue portrait={isPortrait} className='medium'>-</ButtonValue>
                                                        </GridButtom>

                                                        {renderPhNumberButtons(a.animal.id)}
                                                        
                                                        <GridButtom onClick={(e) => {
                                                            handleSquareClick(e)
                                                            handlePhPlusClick(a.animal.id)}}>
                                                            <ButtonValue portrait={isPortrait} className='medium'>+</ButtonValue>
                                                        </GridButtom>
                                                    </GridButtonsPhGrContainer>
                                                </GridColumn>
                                    </DropdownContent>

                                    <DropdownContent portrait={isPortrait}>
                                         <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                             <GridTitle className='medium'>GR(mm)</GridTitle>
                                             <GridButtonsPhGrContainer portrait={isPortrait}>
                                                 <GridButtom portrait={isPortrait} onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleMinusClick(a.animal.id)}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>-</ButtonValue>
                                                 </GridButtom>
         
                                                 {renderGrNumberButtons(a.animal.id)}
                                                 
                                                 <GridButtom portrait={isPortrait} onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handlePlusClick(a.animal.id)}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>+</ButtonValue>
                                                 </GridButtom>
                                             </GridButtonsPhGrContainer>
                                         </GridColumn>
                                    </DropdownContent>
 
                                    <ButtonContainer>
                                        <Button style={{marginRight:'2%', backgroundColor:'#d25650', borderColor:'#d25650'}} 
                                            onClick={()=>{
                                                softDeleteAnimal(a.animal.id,a.animal.carcass_id)
                                            }}
                                        >
                                            Excluir
                                        </Button>
                                        <Button
                                            onClick={(e) => {
                                                handleSquareClick(e)
                                                handleOpenModal(a)
                                                }}
                                        >
                                                Finalizar
                                        </Button>
                                    </ButtonContainer>
                                   
                                 </DropdownContentContainer> 
                             </DropdownContainer>
                         </AnimalContainer>
                        </>
                     ))}
                 </>
    )
}