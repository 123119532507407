import React, { useEffect, useState } from 'react'
import { 
    CancelButton,
    ContentContainer, 
    DeleteModal, 
    ModalActions, 
    ModalContainer, 
    ModalContent, 
    ModalHeader, ExcludeButton } from './styles';
import { FaCircleCheck } from "react-icons/fa6";

export default function ModalEvaluations({ animal, handleCloseModal, open, setOpen, handleUpdateAnimal }){

    return(<ModalContainer visible={open} onClick={handleCloseModal}>
        <DeleteModal> 
            <ContentContainer>
                <FaCircleCheck size={40} color='#275224'/>                                                  
                <ModalHeader className='medium'> Finalizar Animal </ModalHeader>
                <ModalContent className='small'>Você tem certeza que deseja finalizar este animal?</ModalContent>
                <ModalActions>
                    <CancelButton
                        className="button"
                        style={{marginRight:'50px'}}
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Cancelar
                    </CancelButton>
                    <ExcludeButton
                        className="button"
                        onClick={() => handleUpdateAnimal(animal)}
                    >
                        Confirmar
                    </ExcludeButton>
                </ModalActions>
            </ContentContainer>
        </DeleteModal>
    </ModalContainer>)
}