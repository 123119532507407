import React, {useEffect, useState} from "react";
import { 
    BiggerContainer, 
    BiggerInput1, 
    BiggerInput2, 
    CheckboxContainer, 
    CompliancesContainer, 
    CompliancesDeaths, 
    CompliancesInputsContainer, 
    CompliancesItems, 
    CompliancesObservation, 
    CompliancesText, 
    Container, 
    FakeBorder, 
    FakeContainer, 
    HiddenCheckbox, 
    Icon, 
    Input, 
    InputDivisionLeft, 
    InputDivisionRight, 
    InputTitle, 
    InputsContainer, 
    NextButton, 
    Select, 
    SmallerInput, 
    SmallersContainer, 
    StyledCheckbox, 
    Title,
    AddNewProductor,
    AddProductorButton,
    AddNewFarm,
    AddFarmButton
} from "./styles";
import API from "../../services/API";
import Cookies from "js-cookie";
import Helpers from "../../services/helpers";

import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import br from '../../lang/br.json'
import axios, { Axios } from "axios";
import { setBatchToUpdate } from "../../store/modules/batch/actions";
import ModalAddFarm from "../Modal/Farm";
import ModalAddProductor from "../Modal/Productor";

export default function PreSlaughter ({ onNextButtonClick, allErrors }) {

    // Para recuperação de dados
    const { batch } = useSelector((state) => state.Reducers);
    const dispatch = useDispatch();

    const editMode = batch?.id?true:false;

    // Constantes das opções em tela
    const [productors, setProductors] = useState([]);
    const [farms, setFarms] = useState([]);
    const [races, setRaces] = useState([]);
    const [shears, setShears] = useState([
        "Raça deslanada", 
        "Inferior à 15 dias (< 4 mm)", 
        "Superior à 15 dias (> 4 mm)"
    ]);

    // Constantes que guardam o valor escolhido
    const [productor, setProductor] = useState(null);
    const [farm, setFarm] = useState(null);
    const [aliveWeigth, setAliveWeigth] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [race, setRace] = useState([]);
    const [shear, setShear] = useState(null);
    const [compliances, setCompliances] = useState({
        questionOne:   false,
        questionTwo:   false,
        questionThree: false,
        questionFour:  false,
        questionFive:  false
    }); 
    const [deaths,setDeaths] = useState('');
    const [observations,setObservations] = useState('');

    const [showProducerModal, setShowProducerModal] = useState(false);
    const [showFarmModal, setShowFarmModal] = useState(false);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]); 
    const [cities, setCities] = useState([]); 

    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')

    const [productorData, setProductorData] = useState({ name: '', cpf: '', cnpj: '', email: '' });
    const [errorsProductor, setErrorsProductor] = useState('')

    const [farmData, setFarmData] = useState({
        farmName: '',
        productor: productors[productor] || '',
        farmCountry: 'Brasil',
        farmState: '',
        farmCity: '',
        farmStreet: '',
        farmCEP: '',
        farmCNPJ: '',
        farmIE: ''
      });

    const [errors, setErrors] = useState('');
    
    useEffect(()=>{
        
        getProductors()
        getRaces()
        getCountries()

        if(batch && (String(batch.status) === "CREATED" || batch.status === null)){
            setErrors(allErrors);
            setAliveWeigth(batch.avg_live_weight??'');
            setMaxAge(batch.max_age_declared??'');
            setShear(batch.shearing_status??null);
            setDeaths(batch.deaths??'');
            setObservations(batch.observation??'');
            if(Object.keys(batch.productor).length > 0){
                setProductorData({name: batch.productor.user.name, cpf: batch.productor.cpf, cnpj: batch.productor.cnpj, email: batch.productor.user.email})
            }
            if(Object.keys(batch.farm).length > 0){
                setFarmData((prevData) => ({
                    ...prevData,
                    productor: batch.productor,
                  }));
            }
            if(Object.keys(batch.compliance).length > 0){
                setCompliances({
                questionOne: batch.compliance.question_one,
                questionTwo: batch.compliance.question_two,
                questionThree: batch.compliance.question_three,
                questionFour: batch.compliance.question_four,
                questionFive: batch.compliance.question_five
                })
            }
        }
    },[])

    useEffect(()=>{
        if(String(farmData.farmName) !== ''){
            farms.find((item, index) => {
                if(String(item.name) === String(farmData.farmName)){
                        setFarm(index)
                        dispatch(setBatchToUpdate({ farm: farms[index], registering:true}))
                }
            })
        }
    },[farms])

    useEffect(()=>{
        if(Object.keys(productorData).length > 0){
            productors.map((productor,index)=>{
                if(productorData.name === productor.user.name){
                    return selectProductor(index)
                }
            })
        }
    },[productors])

    useEffect(()=>{
        if(countries.length > 0){
            getStates(countries.find((country) => country.eName === 'Brazil' ))
            setSelectedCountry(countries.find((country) => country.eName === 'Brazil' ))
        }
    },[countries])

    const getCountries = async () => {
        try {
          const response = await axios.get('https://countriesnow.space/api/v0.1/countries');
      
          const countryData = response.data.data;
          const countryNamesInPortuguese = countryData.map((country,index) => {
              
              const translatedName = br.countries[country.iso2];
              return {
                  id: index + 1,
                  cca2: country.iso2,
                  name: translatedName ? translatedName : country.country,
                  eName: country.country
              };
            });
            countryNamesInPortuguese.sort((a, b) => a.name.localeCompare(b.name));
      
            return setCountries(countryNamesInPortuguese);
        } catch (error) {
            console.log('error getting countries',error.message)
        }
      };

    const getStates = async (country) => {

        try {
          if(country !== ''){
      
              const response = await axios.post('https://countriesnow.space/api/v0.1/countries/states',{country: `${country.eName}`});
              const statesData = response.data.data;
              
              let states = statesData.states.map((state, index) => {
                  let stateName = '';
                  switch (state.name) {
                      case 'Federal District':
                          stateName = 'Brásilia';
                          break;
                      default:
                          stateName = '';
                  }
      
                  return (
                      {
                          id: index + 1,
                          code: state.state_code,
                          name: stateName === '' ?  state.name.replace(/District|Province|Department/i, '').trim() : stateName,
                          originalName : state.name
                      }
              )});
      
              states.sort((a, b) => a.name.localeCompare(b.name));
              return setStates(states);
      
          }
        } catch (error) {
            console.log('Error getting states', error.message);
            return [];
        }
      }
    
      const getCities = async (country,state) => {
      try {
            if(state !== '' && country !== '' ){
                
                const response = await axios.post('https://countriesnow.space/api/v0.1/countries/state/cities',{country: `${country}`, state: `${state}`})
                let cities = response.data.data
    
                let auxCities = []
                cities.sort((a, b) => a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1)
                
                cities.forEach((city, index) => {
                    return auxCities.push({
                        id: index + 1,
                        name: city
                    })
                })
    
                return setCities(auxCities)
            }
        } catch (error) {
            console.log('error getting cities', error.message)
        }
    }

    async function getProductors(){
        await API.get('productors')
        .then((res)=> {

            setProductors(res.data)
            
            if(Object.keys(batch.productor).length > 0 && 
              (batch.status == "CREATED" || batch.status == null)
              ){
                let prods = res.data;

                prods.map((p, index)=>{
                    if(p.id === batch.productor.id){
                        setProductor(index);
                        getFarms(p.id)
                    }
                })
            }
        })
        .catch((err)=>{})
    }

    async function selectProductor(index){
        
        if(index != -1){

        let productor = {...productors[index]};
        getFarms(productor.id);
        setProductor(index);
        setFarmData((prevData) => ({
            ...prevData,
            productor: productors[index],
          }));
        dispatch(setBatchToUpdate({ productor: productor, registering:true }));

        }else{
        setProductor(index);
        setFarms([]);
        }

    }

    async function getFarms(productorId){
        try {
            const res = await API.get(`farms/productors/${productorId}`);
            if (res.data) {
                setFarms(res.data);
                if(editMode){
                    // pegar o index do batch que vem da lista de lotes e verificar qual é a fazenda selecionada
                    res.data.find((farm,index)=> {
                        if(String(farm.name) === String(batch.farm.name)){
                            return setFarm(index)
                        }
                    }) 
                }
            } else {
                console.error("No data returned from API");
                setFarms([]); 
            }
        } catch (err) {
            console.error("Error fetching farms:", err);
        }
    }

    async function getRaces(){
        await API.get('races')
        .then((res)=> {
            setRaces(res.data)

            if(batch.races.length > 0 && 
             ((batch.status == "CREATED" || batch.status == null))
              ){
                setRace(batch.races)
                // console.log(batch.races[0].id)
                // res.data.map((r,index)=>{
                //     if(r.id == batch.races){
                //     setRace([index]);
                //     }
                // })

            }
        })
        .catch((err) => console.log(err))
    }

    const handleAliveWeigthChange = (event) => {

        let { value } = event.target;

        if(value <= 100){

        let result = Helpers.formatAliveWeight(value);

        setAliveWeigth(result);  
        dispatch(setBatchToUpdate({ avg_live_weight: result, registering:true }));
    }       
    };

    const handleMaxAgeChange = (event) => {
        
        let { value } = event.target;

        let result = Helpers.formatMaxAge(value);

        setMaxAge(result);   
        dispatch(setBatchToUpdate({ max_age_declared: result, registering:true }));

    };

    const handleDeathsChange = (event) => {
        let { value } = event.target;

        if (/^\d*$/.test(value)) {
            if (value.length > 3) {
                value = value.slice(0, 3);
            }
            setDeaths(value); 
            dispatch(setBatchToUpdate({ deaths: value, registering:true }));
        }
                  
    };

    function handleCompliance(compliance) {
     
        let c = {...compliances};
        c[compliance] = !c[compliance];
        
        setCompliances(c);
        dispatch(setBatchToUpdate(
        { 
        compliance: {
            question_one: c.questionOne,
            question_two: c.questionTwo,
            question_three: c.questionThree,
            question_four: c.questionFour,
            question_five: c.questionFive
        },
        registering:true 
        }
        ));
     
    }

    const handleRaces = (value, action) =>{

        let raceAux = [...race];
        if(action === "add"){
        raceAux.push(races[value]);
        }else {
            raceAux.splice(value,1);
        }
        setRace(raceAux);
        dispatch(setBatchToUpdate({ races: raceAux, registering:true }))

    }

    async function saveUpdates(){

        setErrors({});

        try{
    
            let data = Helpers.preparePreSlaughterData({
                maxAge,
                aliveWeigth,
                farm: farms[farm]?.id??null,
                productor: productors[productor]?.id??null,
                shear: shear,
                userId: parseInt(Cookies.get("sheep-user-id")),
                compliance: batch.compliance
            });

            console.log('data save update', data)
            if(String(data.message) === "Sucesso"){
            
                data.data.deaths = String(deaths) === '' ? 0 : deaths;
                data.data.observation = observations??'';
                data.data.compliance_id = batch.compliance_id

            const res = await API.put(`batches/${batch.id}`, { batch: {...data.data }, races: race });
            
            }else{
                setErrors(data.emptyValues)
            }
            onNextButtonClick();
        } catch(err){
            console.log(err)
        }

    }

    const handleShowModalProducer = (e) => {
        e.preventDefault();
        setErrorsProductor('')
        setProductorData({ name: '', cpf: '', cnpj: '', email: '' })
        setShowProducerModal(true)
    }

    const handleShowModalFarm = (e) => {
        e.preventDefault();
        setFarmData(
            {
                farmName: '',
                productor: productors[productor] || '',
                farmCountry: 'Brasil',
                farmState: '',
                farmCity: '',
                farmStreet: '',
                farmCEP: '',
                farmCNPJ: '',
                farmIE: ''
            }
        )
        setShowFarmModal(true);
    }

    const handleProducerSubmit = async (e) => {

        e.preventDefault();
        const uuid = uuidv4();
        console.log(productorData)
        const cpfProductor = productors.find((item) => String(item.cpf) === String(productorData.cpf))
        const cnpjProductor = productors.find((item) => String(item.cnpj) === String(productorData.cnpj))

        if(cpfProductor || cnpjProductor){
            
            if(cpfProductor && productorData.cpf !== ''){
                setProductorData({ name: '', cpf: null, cnpj: null, email: '' })
               return setErrorsProductor("CPF já cadastrado")
            }else if(cnpjProductor && productorData.cnpj !== ''){
                setProductorData({ name: '', cpf: null, cnpj: null, email: '' })
               return setErrorsProductor("CNPJ já cadastrado")
            }
        }else{
            if(!productorData.cpf && !productorData.cnpj){
                setErrorsProductor("CPF ou CNPJ é obrigatório");
            }else{
                const payload = {
                    name: productorData.name,
                    username: `user_${uuid}`,
                    email: productorData.email ? `${productorData.email}` : `${uuid}@example.com`,
                    password: uuid,
                }
        
                const res = await API.post("users", payload);
                if(res.status === 200){
                    setShowProducerModal(false);
                    const user = res.data
                    
                    const productorPayload = {
                        user_id: user.id,
                        cnpj: productorData.cnpj,
                        cpf: productorData.cpf
                    }
                    
                    await API.post('productors', productorPayload).then((response)=>{
                        if(response.status === 200){
                            getProductors()
                            setShowProducerModal(false)
                        }
                    })
                    }
            }  
        }  
    };
    
    const handleFarmSubmit = async (e) => {
    e.preventDefault();

    const addressPayload = {
        city: farmData.farmCity,
        state: farmData.farmState,
        street: farmData.farmStreet,
        country: farmData.farmCountry,
        post_code: farmData.farmCEP,
    }

    const address = await API.post('addresses',addressPayload)

    let productorAux = productors.find((item,index)=>{
        if(index === parseInt(productor)){
            return item
        }
    })

    const farmPayload ={
        name: farmData.farmName,
        creation_date: new Date(),
        address_id: address.data.id,
        productor_id: productorAux.id,
        cnpj: farmData.farmCNPJ,
        ie: farmData.farmIE
    }

    await API.post('farms', farmPayload).then((res)=>{
        if(res.status === 200){
            setShowFarmModal(false)
            getFarms(productorAux.id)
        }
    })
    
    
    };

    return(
        <Container>
            <Title className="large">Origem</Title>
            <InputsContainer>
                <InputDivisionLeft>
                    <InputTitle className="medium">Nome do Produtor</InputTitle> 
                    <FakeContainer>
                        <Select value={productor??-1} onChange={(e)=> selectProductor(e.target.value)} error={errors.productor}>
                        <option value={-1}>Selecione um produtor</option>
                        {productors?.map((p, index) => (
                                <option key={index} value={index}>
                                    {p.user.name}
                                </option>
                            ))}

                        </Select>
                        <FakeBorder error={errors.productor}/>
                    <AddNewProductor>
                        <AddProductorButton onClick={(e)=>{handleShowModalProducer(e)}}>+</AddProductorButton>
                    </AddNewProductor>
                    </FakeContainer>
                    <SmallersContainer>
                        <SmallerInput>
                                <InputTitle className="medium">Peso Vivo Médio (kg)</InputTitle> 
                                <Input value={aliveWeigth} onChange={handleAliveWeigthChange} placeholder="0" error={errors.aliveWeigth}/>
                        </SmallerInput>
                        <SmallerInput>
                                <InputTitle className="medium">Idade Máxima (meses)</InputTitle> 
                                <Input value={maxAge} onChange={handleMaxAgeChange} placeholder="0" error={errors.maxAge}/>
                        </SmallerInput>
                    </SmallersContainer>                
                </InputDivisionLeft>
                <InputDivisionRight>
                        <InputTitle className="medium">Fazenda</InputTitle> 
                        <FakeContainer>
                            <Select 
                                value={farm??-1}
                                disabled={!productor || farms.length === 0}
                                onChange={(e)=>{
                                    setFarm(e.target.value) 
                                    dispatch(setBatchToUpdate({ farm: farms[e.target.value], registering:true}))
                                
                                }}
                                error={errors.farm}>
                            <option>{!productor ?" ← Selecione um produtor primeiro": farms.length === 0 ? "Nenhuma fazenda cadastrada" :"Selecione uma fazenda"}</option>
                            {farms.map((f, index) => (
                                    <option key={index} value={index}>
                                        {f.name}
                                    </option>
                                ))}
                            </Select>
                            <FakeBorder error={errors.farm}/>
                            <AddNewFarm>
                                <AddFarmButton disabled={!productor} onClick={(e)=>{handleShowModalFarm(e)}}>+</AddFarmButton>
                            </AddNewFarm>
                        </FakeContainer>                        
                        <BiggerContainer>
                            <BiggerInput1>
                                <InputTitle className="medium">Raça(s)</InputTitle>
                                <div style={{ display:'flex', flexDirection:'row', height:'100%', alignItems:'center', justifyContent:'space-between' }}>
                                    <div style={{ display:'flex', alignItems:'center', width:'100%', height:'35px', flexWrap:'wrap', overflowY:'scroll', padding:'5px', marginTop:5, backgroundColor:'#F7F7F7', borderRadius: '5px 0px 0px 5px' }}>
                                        {race.map((r,index)=> (
                                            <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:'#E6E6E6', width:'fit-content', height:20, padding:'2px 8px', margin:2, borderRadius: 50, color:'#575757' }}>
                                                <div style={{ marginRight:3, fontSize:12, cursor:'pointer' }} onClick={()=>{handleRaces(index, 'remove');}}>
                                                    x
                                                </div>
                                                <div>{r.name}</div>
                                            </div>
                                        ))}  
                                    </div>
                                <FakeContainer> 
                                    <Select value={-1} style={{ width:'7ch', borderRadius:'0px'}} onChange={(e)=>{
                                        handleRaces(e.target.value, 'add');
                                        }} error={errors.race}>
                                    <option value={-1} style={{marginRight:'3px'}}>Raças</option>
                                    {races?.map((r, index) => (
                                        <option key={index} value={index}>
                                            {r.name}
                                        </option>
                                    ))}
                                    </Select>
                                    <FakeBorder error={errors.race}/>
                                </FakeContainer>     
                                </div>
                            </BiggerInput1>
                            <BiggerInput2>
                                <InputTitle className="medium">Tosquia</InputTitle> 
                                <FakeContainer>
                                    <Select value={shear} onChange={(e)=>{
                                        setShear(e.target.value)
                                        dispatch(setBatchToUpdate({ shearing_status:e.target.value, registering:true }))
                                        }} error={errors.shear}>
                                    <option>Selecione a tosquia</option>
                                    {shears?.map((s, index) => (
                                        <option key={index} value={index}>
                                            {s}
                                        </option>
                                    ))}
                                    </Select>
                                    <FakeBorder error={errors.shear}/>
                                </FakeContainer> 
                            </BiggerInput2>
                        </BiggerContainer>
                </InputDivisionRight>
            </InputsContainer>
            <Title className="large" style={{paddingTop:'50px'}}>Conformidades</Title>
            <CompliancesContainer>
                <CompliancesItems>
                    <CompliancesText className="small">Permanência mínima de 2 semanas na fazenda despachante, evitando a troca de ambiente pré-abate</CompliancesText>
                    <CheckboxContainer onClick={()=>handleCompliance("questionOne")}>
                    <HiddenCheckbox checked={compliances.questionOne} />
                    <StyledCheckbox checked={compliances.questionOne}>
                    <Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                    </StyledCheckbox>
                </CheckboxContainer>
                </CompliancesItems>
                <CompliancesItems>
                    <CompliancesText className="small">Ganho de peso mínimo de 100 g/dia por 2 semanas antes do abate</CompliancesText>
                    <CheckboxContainer onClick={()=>handleCompliance("questionTwo")}>
                    <HiddenCheckbox checked={compliances.questionTwo}/>
                    <StyledCheckbox checked={compliances.questionTwo}>
                    <Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                    </StyledCheckbox>
                </CheckboxContainer>
                </CompliancesItems>
                <CompliancesItems>
                    <CompliancesText className="small">Tempo total entre jejum alimentar e abate não deve ser superior à 48h</CompliancesText>
                    <CheckboxContainer onClick={()=>handleCompliance("questionThree")}>
                    <HiddenCheckbox checked={compliances.questionThree}/>
                    <StyledCheckbox checked={compliances.questionThree}>
                    <Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                    </StyledCheckbox>
                </CheckboxContainer>
                </CompliancesItems>
                <CompliancesItems>
                    <CompliancesText className="small">Os animais devem ter acesso a água em todo o momento em que não estiverem em trânsito </CompliancesText>
                    <CheckboxContainer onClick={()=>handleCompliance("questionFour")}>
                    <HiddenCheckbox checked={compliances.questionFour}/>
                    <StyledCheckbox checked={compliances.questionFour}>
                    <Icon viewBox="0 0 24 28">
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                    </StyledCheckbox>
                </CheckboxContainer>
                </CompliancesItems>
                <CompliancesItems>
                    <CompliancesText className="small">Tempo em trânsito deve ser inferior à 24h</CompliancesText>
                    <CheckboxContainer onClick={()=>handleCompliance('questionFive')}>
                    <HiddenCheckbox checked={compliances.questionFive} />
                    <StyledCheckbox checked={compliances.questionFive}>
                    <Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                    </StyledCheckbox>
                </CheckboxContainer>
                </CompliancesItems>
                <CompliancesInputsContainer>
                    <CompliancesDeaths>
                        <InputTitle className="medium">Óbitos</InputTitle> 
                        <Input value={deaths} onChange={handleDeathsChange} placeholder="0"/>  
                    </CompliancesDeaths>
                    <CompliancesObservation>
                        <InputTitle className="medium">Observações</InputTitle> 
                        <Input value={observations} onChange={(e)=>{
                            setObservations(e.target.value)
                            dispatch(setBatchToUpdate({ observation: e.target.value, registering:true }))
                            }}/>  
                    </CompliancesObservation>
                    <NextButton className="medium" onClick={editMode?saveUpdates:onNextButtonClick}>{editMode?'Salvar':'Próximo'}</NextButton>
                </CompliancesInputsContainer>
            </CompliancesContainer>
            {/* Producer Modal */}
            <ModalAddProductor
                show={showProducerModal}
                onClose={() => setShowProducerModal(false)}
                productorData={productorData}
                setProductorData={setProductorData}
                handleSubmit={handleProducerSubmit}
                error={errorsProductor}
            />

            {/* Farm Modal */}
            <ModalAddFarm
                show={showFarmModal}
                onClose={() => setShowFarmModal(false)}
                handleSubmit={handleFarmSubmit}
                countries={countries}
                states={states}
                cities={cities}
                getCities={getCities}
                setSelectedState={setSelectedState}
                setSelectedCity={setSelectedCity}
                selectedState={selectedState}
                setFarmData={setFarmData}
                farmData={farmData}
            />
        </Container>
    )
}