import React, { useCallback, useEffect, useState, useRef } from "react";
import { ButtonsContainer, Container, ContentContainer, FileContainer, Input, InputTitle, InputsContainer, ItemsContainer, ListContainer, ListItem,
   ModalItensContainer, SearchInput, SheetButton, SheetLink, SheetText,TableContainer,TableContent,TableHeader,TableTitle,Title, WarningContainer,
    WarningContent, ModalContainer } from "./styles";
import planilhaPadrao from '../../files/planilha_padrao_sheep.xlsx';
import { FaCircleExclamation } from "react-icons/fa6";
import { GoTrash } from "react-icons/go";
import CustomButton from "../CustomButton";
import * as XLSX from 'xlsx';
import Helpers from "../../services/helpers";
import { useWarn } from "../../context/Modal"; 
import { StyledCheckbox, Icon } from "../PreSlaughter/styles";
import API from "../../services/API";
import Cookie from 'js-cookie';
import { useNavigate } from "react-router";
import { DeleteModal, ModalHeader, ModalContent, ModalActions, CancelButton, ExcludeButton } from "../../pages/Batches/styles";
import { IoWarning } from "react-icons/io5";

export default function Animals ({ batchInfo }) {

    const [searchId, setSearchId] = useState('');
    const [data, setData] = useState([]);
    const [excelData, setExcelData] = useState(null);
    const [initialIdToUpload, setInitialIdToUpload] = useState(null);
    const [finalIdToUpload, setFinalIdToUpload] = useState(null);
    const [currentAnimalKey, setCurrentAnimalKey] = useState('');
    const [batchClassification, setBatchClassification] = useState('');
    const [deleteModalIndex, setDeleteModalIndex] = useState(null);
    const [animalsWithInitial, setAnimalsWithInitial] = useState(0);
  
    const technitianId = Cookie.get('sheep-technician')

    const [theresDorper, setTheresDorper] = useState(false);

    const { warn, closeWarn } = useWarn();
    const navigate = useNavigate();
    const modalRef = useRef();
    const fileRef = useRef();

    useEffect( () => {
      if (batchInfo.id !== undefined && batchInfo.id !== null){

        batchInfo.races.map((race)=>{
          if(race.id == 1){
            setTheresDorper(true)
          }
        })

        API.get('animalsFromBatch/'+ batchInfo.id).then((res)=>{
          let auxData = res.data
          auxData.forEach((item)=>{ 
            if(item.evaluations.temperature !== null){
              item.evaluations.temperature = Helpers.InverseVerifyTemperatureInterval(item.evaluations.temperature)
            }
           })
          setData(auxData)
        })
      }
    } ,[batchInfo]);

    useEffect(() => {
        if (excelData) {
          handleNewAnimalsUpload();
        }
      }, [excelData]);

    useEffect(()=>{
      // handleColdShortening(data)
      let analizedAnimals = 0;

      data.map((item)=> {
        if(item.animal.initial_pH && (item.animal.initial_temp !== null && item.animal.initial_temp !== undefined)){
          analizedAnimals++;
          setAnimalsWithInitial(analizedAnimals);
        }
      })

    },[data])

    const handleColdShortening = (data) =>{
      
      let totalAnimals = data.length
      let analizedAnimals = 0
      let secondAnalizedAnimals = 0
      let compliance = 0
      let noncompliance = 0

       data.map((item)=> {
        if(item.animal.initial_pH && (item.animal.initial_temp !== null && item.animal.initial_temp !== undefined)){
          analizedAnimals++;
          setAnimalsWithInitial(analizedAnimals);
        }
        if(item.animal.initial_pH > 6 && item.animal.initial_temp < 18){
          secondAnalizedAnimals++
        }
      })

      let percentage = totalAnimals*0.1
      if(analizedAnimals >= percentage){
        compliance++
      }else{
        noncompliance++
      }

      let analizedPercentage = analizedAnimals*0.5
      if(secondAnalizedAnimals <= analizedPercentage){
        compliance++
      }else{
        noncompliance++
      }

      // console.log('totalAnimals',totalAnimals)
      // console.log('analizedAnimals',analizedAnimals)
      // console.log('secondAnalizedAnim',secondAnalizedAnimals)
      // console.log('percentage',percentage)
      // console.log('analizedPer',analizedPercentage)
      // console.log('compliance',compliance)
      // console.log('noncompliance',noncompliance)

      if(compliance === 2){
        setBatchClassification('APROVADO')
        return 'APROVADO'
      }else if(noncompliance === 1 && compliance === 1 ){
        setBatchClassification('APROVADO COM RESSALVAS')
        return 'APROVADO COM RESSALVAS'

        //por enquanto não existe status reprovado, quando tiver descomentar as linhas abaixo
    }else if(noncompliance === 2){
      setBatchClassification('APROVADO COM RESSALVAS')
        return 'APROVADO COM RESSALVAS'
      // setBatchClassification('REPROVADO')
      //   return 'REPROVADO'
    }
    }

    const handleSearchId = (event) => {
        let { value } = event.target;

        if (/^\d*$/.test(value)) {
            if (value.length > 4) {
                value = value.slice(0, 4);
            }
            setSearchId(value); 
        }                  
    };

    const inputMaxLengths = {
      "animal.carcass_id": 5,
      "animal.sex": 5,
      "animal.initial_pH":3,
      "animal.initial_temp": 4,
      "animal.dentition": 2,
      "animal.carcass_weight": 4,
      "animal.dorper":8,
      "evaluations.fat_thickness": 2,
      "evaluations.pH": 3,
      "evaluations.temperature": 4,
      "evaluations.meat_marbling": 2,
    };

    const handleInputDorper = ({ index, name, value }) =>{
      const newData = [...data];
      newData[index].animal[name] = value;
      setData(newData);
    }

    const handleDeleteAnimal = (index) => {
      const newData = [...data];
      newData.splice(index, 1);
      setData(newData);
      handleCloseModal(index)
    };
            
    const handleCloseModal = (e) => {
      if(modalRef.current && !modalRef.current.contains(e.target))
      setDeleteModalIndex(null)
    }

    const handleDeleteIndex = (index) => {
      setDeleteModalIndex(index);
  };

  const formatSex = (sex) => {
    if (
        sex === 'f' ||
        sex === 'femea' ||
        sex === 'fêmea' ||
        sex === 'f ' ||
        sex === 'femea ' ||
        sex === 'fêmea ' ||
        sex === 'F' ||
        sex === 'F '
    ) {
        return 'femea';
    } else if (
      sex === 'mi' ||
      sex === 'macho inteiro' ||
      sex === 'machointeiro' ||
      sex === 'MI' ||
      sex === 'MI ' ||
      sex === 'mi ' ||
      sex === 'macho inteiro ' ||
      sex === 'machointeiro '
    ) {
        return 'mi';
    } else if (
      sex === 'mc' ||
      sex === 'macho castrado' ||
      sex === 'machocastrado' ||
      sex === 'mc ' ||
      sex === 'MC' ||
      sex === 'MC ' ||
      sex === 'macho castrado ' ||
      sex === 'machocastrado '
    ) {
        return 'mc';
    }
    return sex;
};

    const handleInputChange = useCallback((index, event) => {
      const { name, value } = event.target;
    const maxLength = inputMaxLengths[name];

    if (value.length <= maxLength) {
        const newData = [...data];
        const truncatedValue = value.replace('-', '');

        if (name.startsWith('animal.')) {
            const animalKey = name.replace('animal.', '');

            if (animalKey === 'sex') {
              newData[index].animal[animalKey] = formatSex(truncatedValue);
          } else {
              newData[index].animal[animalKey] = truncatedValue;
          }

        } else if (name.startsWith('evaluations.')) {
            const evaluationKey = name.replace('evaluations.', '');
            newData[index].evaluations[evaluationKey] = truncatedValue;
        } else {
            newData[index][name] = truncatedValue;
        }

        setData(newData);
    }
}, [data, inputMaxLengths]);

const handleMarblingChange = useCallback((index, event) => {
  const { name, value } = event.target;
  const newData = [...data];
  let truncatedValue = value.replace('-', '');
      if (name.startsWith('animal.')) {
          const animalKey = name.replace('animal.', '');
          newData[index].animal[animalKey] = truncatedValue;
      } else if (name.startsWith('evaluations.')) {
          const evaluationKey = name.replace('evaluations.', '');
          newData[index].evaluations[evaluationKey] = truncatedValue;
      }
  

  setData(newData);
}, [data]);

const handleCarcassChange = useCallback((index, event) => {
  const { name, value } = event.target;
  const newData = [...data];

  let newValue = value.replace(/[^\d,]/g, '').replace(/\./g, ',');

  if(newValue > 100){
    newValue = 100
  }

  
  if (name.startsWith('animal.')) {
      const animalKey = name.replace('animal.', '');
      newData[index].animal[animalKey] = newValue;
  } else if (name.startsWith('evaluations.')) {
      const evaluationKey = name.replace('evaluations.', '');
      newData[index].evaluations[evaluationKey] = newValue;
  } else {
      newData[index][name] = newValue;
  }

  setData(newData);
}, [data]);

    function getKeysRow() {
        for (let row of excelData) {
          if (row.__rowNum__ === 16) {
            return row;
          }
        }
        return undefined;
    }

    function parseTableKeys() {
      try{
        let keysRow = getKeysRow();
        let tableKeys = {};
    
        if (keysRow !== undefined) {
          let allKeys = Object.keys(keysRow)
    
          for (let key of allKeys) {
            
            let rowValue = keysRow[key].replace(/(\r\n|\n|\r)/gm, " ");
            if (rowValue == 'ID  Seq.') {
                tableKeys.id = key;
            } else if (rowValue.includes('(F, MC, MI) Sexo')) {
                tableKeys.sex = key;
            } else if (rowValue.includes('(0, 2, 4, 6, 8) Dentição')){
                tableKeys.dentition = key;
            } else if (rowValue.includes('PCQ (kg)')){
                tableKeys.PCQ = key;
            }else if (rowValue.includes('Carneiro')){
              tableKeys.sheep = key;
          } else if(rowValue.includes('pH  Inicial')){
                tableKeys.phInitial= key;
            } else if(rowValue.includes('Temp. Inicial')){
                tableKeys.initialTemperature = key;
            } else if (rowValue.includes('GR (mm) Acabamento')) {
                tableKeys.fatThickness = key;
            } else if (rowValue.includes('Temp. Final')){
                tableKeys.finalTemperature = key;
            } else if (rowValue.includes('pH final')){
                tableKeys.finalPh = key;
            } else if (rowValue.includes('Marmoreio')){
                tableKeys.marbling = key;
            } else if (rowValue.includes('Número  Lote')) { }
            else {
              return false
            }
          }
        } else return false
        return tableKeys;
      }catch(err){
        console.log(err)
      }
    }

    function extractAnimalFromRowAttributes(row, keys) {

      try{

        let newAnimal = {
          animal:{},
          evaluations:{}
        };
        
        let requiredAttributes = 0;
    
        if (row[keys.id] && !isNaN(row[keys.id])) {
          newAnimal.animal.carcass_id = row[keys.id];
          requiredAttributes++;
        }else{
          newAnimal.animal.carcass_id = row[keys.id];
          requiredAttributes++;
        }
    
        if (row[keys.sex] && row[keys.sex].length > 0) {
          let sex = row[keys.sex].toLowerCase();
          
          if (
            sex === 'f' ||
            sex === 'femea' ||
            sex === 'fêmea' ||
            sex === 'f ' ||
            sex === 'femea ' ||
            sex === 'fêmea ' ||
            sex === 'F' ||
            sex === 'F '
          ) {
            newAnimal.animal.sex = 'femea';
            requiredAttributes++;
          } else if (
            sex === 'mi' ||
            sex === 'macho inteiro' ||
            sex === 'machointeiro' ||
            sex === 'MI' ||
            sex === 'MI ' ||
            sex === 'mi ' ||
            sex === 'macho inteiro ' ||
            sex === 'machointeiro '
          ) {
            newAnimal.animal.sex = 'mi';
            requiredAttributes++;
          } else if (
            sex === 'mc' ||
            sex === 'macho castrado' ||
            sex === 'machocastrado' ||
            sex === 'mc ' ||
            sex === 'MC' ||
            sex === 'MC ' ||
            sex === 'macho castrado ' ||
            sex === 'machocastrado '
          ) {
            newAnimal.animal.sex = 'mc';
            requiredAttributes++;
          }
        }
    
        if (!isNaN(parseInt(row[keys.dentition]))) {
          let dentition = parseInt(row[keys.dentition]);
          if (dentition % 2 !== 0) {
            dentition++;
          }
          if (dentition > 8) {
            dentition = 8;
          }
          if (dentition < 0) {
            dentition = 0;
          }
          newAnimal.animal.dentition = dentition;
          requiredAttributes++;
        }
    
        if (
          row[keys.PCQ] &&
          !isNaN(parseFloat(Helpers.VerifyCarcassWeight(row[keys.PCQ])))
        ) {
          newAnimal.animal.carcass_weight = parseFloat(row[keys.PCQ]);
          requiredAttributes++;
        } else if (row[keys.PCQ] < 0) { 
          console.log(`Animal ${row[keys.id]} com PCQ menor do que 0 Kg`)
        } else if (row[keys.PCQ] > 60) {
          console.log(`Animal ${row[keys.id]} com PCQ maior do que 60 Kg`)
        }

        if(row[keys.sheep] && row[keys.sheep] !== ""){
          newAnimal.animal.sheep = row[keys.sheep];
        }
    
        if(row[keys.fatThickness] !== null && row[keys.fatThickness] !== undefined && !isNaN(row[keys.fatThickness])){
          newAnimal.evaluations.fat_thickness = parseInt(row[keys.fatThickness]);
        }else{
          newAnimal.evaluations.fat_thickness = null
        }

        if(row[keys.phInitial] !== undefined && row[keys.phInitial] !== null) {

          if (typeof row[keys.phInitial] === 'string') {
        
              const cleanedValue = row[keys.phInitial].replace(/[^0-9,.]/g, '');
              
              const pHValue = cleanedValue.replace(',', '.');
              
              newAnimal.animal.initial_pH = parseFloat(pHValue);
              
              if (newAnimal.animal.initial_pH > 14) {
                  newAnimal.animal.initial_pH = 14;
              }
              
          } else if (typeof row[keys.phInitial] === 'number') {
   
              newAnimal.animal.initial_pH = row[keys.phInitial];
              
              if (newAnimal.animal.initial_pH > 14) {
                  newAnimal.animal.initial_pH = 14;
              }
              
          } else {
              console.error('Valor de pH inválido:', row[keys.phInitial]);
              newAnimal.animal.initial_pH = null;
          }
        } else {
            newAnimal.animal.initial_pH = null;
        }
        
      if(row[keys.initialTemperature] !== '' && !isNaN(row[keys.initialTemperature])){
        newAnimal.animal.initial_temp = parseInt(row[keys.initialTemperature])
      }else{
        newAnimal.animal.initial_temp = null
      }

      if((row[keys.finalTemperature] !== '' && !isNaN[row[keys.finalTemperature]]) || row[keys.finalTemperature] === 0){
        newAnimal.evaluations.temperature = Helpers.VerifyTemperatureInterval(parseFloat(row[keys.finalTemperature]))
      }else{
        newAnimal.evaluations.temperature = null
      }
    

      if (row[keys.finalPh]) {

        if (typeof row[keys.finalPh] === 'string') {

            const cleanedValue = row[keys.finalPh].replace(/[^0-9,.]/g, '');
            
            const pHValue = cleanedValue.replace(',', '.');
            
            newAnimal.evaluations.pH = parseFloat(pHValue);
            
            if (newAnimal.evaluations.pH > 14) {
                newAnimal.evaluations.pH = 14;
            }

        } else if (typeof row[keys.finalPh] === 'number') {

            newAnimal.evaluations.pH = row[keys.finalPh];
            
            if (newAnimal.evaluations.pH > 14) {
                newAnimal.evaluations.pH = 14;
            }
            
        } else {
            console.error('Valor de pH final inválido:', row[keys.finalPh]);
            newAnimal.evaluations.pH = null;
        }
      } else {
          newAnimal.evaluations.pH = null;
      }

      if(row[keys.marbling] && !isNaN(row[keys.marbling]) || row[keys.marbling] === 0 ){
          newAnimal.evaluations.meat_marbling = parseInt(row[keys.marbling])
      }else{
        newAnimal.evaluations.meat_marbling = null
      }

        if(requiredAttributes < 5){
          newAnimal.animal.warning = true
        }else{
          newAnimal.animal.warning = false
        }

        return newAnimal
      }catch(err){
        console.log("Erro:", err)
      }
    }

    function getValidAnimals() {
        let animals = [];
        let data = excelData;
        let tableKeys = parseTableKeys();
        let initialId = initialIdToUpload?parseInt(initialIdToUpload):null;
        let finalId = finalIdToUpload?parseInt(finalIdToUpload):null;
    
        if (tableKeys) {
          if (
            Object.keys(tableKeys).length >= 5
          ) {
            for (let row of data) {
              if (row.__rowNum__ > 1515) {
                break;
              }
              if (row.__rowNum__ > 17) {
                let newAnimal = extractAnimalFromRowAttributes(
                  row,
                  tableKeys
                );

                if(initialId && finalId){
                  if(newAnimal &&
                    parseInt(newAnimal.animal.carcass_id) >= parseInt(initialId) &&
                    parseInt(newAnimal.animal.carcass_id) <= parseInt(finalId)){
                      
                      animals.unshift(newAnimal);
                      
                    }
                }else if(!initialId && !finalId){
                  
                  animals.unshift(newAnimal);
                  
                }
              }
            }
          }
        } else {
          
          return false
        }
        return animals
    }

    function handleNewAnimalsUpload() {

        try {
          let newAnimals = getValidAnimals();

          if (!newAnimals) return;
          const hasDuplicateCarcassId = newAnimals.some((animal, index) => {  
            return newAnimals.slice(index + 1).some(otherAnimal => otherAnimal.animal.carcass_id === animal.animal.carcass_id);
          });
        

          if(hasDuplicateCarcassId){
            warn({
              title:"Atenção",
              body:"Existem um ou mais animais com o mesmo ID.",
              showCancelButton:false,
              secondButton:{ label:"Entendi", color:'#A4A4A4' }
            })
            fileRef.current.value = null;
            return;
          }

          if(newAnimals.length > batchInfo.animals_quantity){
            warn({
              title:"Atenção",
              body:"Animais cadastrados excedem a quantidade declarada.",
              showCancelButton:false,
              secondButton:{ label:"Entendi", color:'#A4A4A4' }
            })
            fileRef.current.value = null;
            return;
          }

          const invalidAnimals = [];

          newAnimals.some((e) => {
              if (e.evaluations.meat_marbling !== null && e.evaluations.meat_marbling !== undefined && (e.evaluations.meat_marbling === 0 || e.evaluations.meat_marbling < 1 || e.evaluations.meat_marbling > 5)) {
                  invalidAnimals.push(e.animal.carcass_id);
              }
              return false;
          });

          invalidAnimals.sort((a, b) => a - b);

          if (invalidAnimals.length > 0) {
              warn({
                  title: "Atenção",
                  body: `O Marmoreio ${invalidAnimals.length > 1 ? 'dos' : 'do'} ${invalidAnimals.length > 1 ? 'animais' : 'animal'} ${invalidAnimals.join(', ')} ${invalidAnimals.length > 1 ? 'devem' : 'deve'} ter ${invalidAnimals.length > 1 ? 'valores' : 'valor'} entre 1 e 5.`,
                  showCancelButton: false,
                  secondButton: { label: "Entendi", color: '#A4A4A4' }
              });
              console.log(invalidAnimals);
              fileRef.current.value = null;
              return
          }

          const invalidPCQIds = [];

          if (!newAnimals.every((e) => {
            const weight = e.animal.carcass_weight;
            invalidPCQIds.push(e.animal.carcass_id)
            return weight === null || weight === undefined || (weight >= 0 && weight <= 100);
          })) {

            let grammar = invalidPCQIds.length > 1?["Os","animais","possuem"]:["O","animal","possui"]

            warn({
                title: "Atenção",
                body: `${grammar[0]} ${grammar[1]} ${invalidPCQIds.join(', ')} possui PCQ fora do intervalo 0 - 100 Kg.`,
                showCancelButton: false,
                secondButton: { label: "Entendi", color: '#A4A4A4' }
            });
            fileRef.current.value = null;
            return;
        }
    
          if (newAnimals.length === 0) {
    
            return;
          } else {
    
            if (newAnimals.length > 0) {
              let dataAux = [...data, ...newAnimals]
              dataAux.sort((a,b)=>a.animal.carcass_id - b.animal.carcass_id)

              dataAux.forEach((animal) => {
                animal.animal.dorper = theresDorper ? true : false;
            });

            setData(dataAux);
              setCurrentAnimalKey(currentAnimalKey + newAnimals.length);
            }
    
          }
        } catch (err) {
    
          console.log('A planilha inserida é diferente da padrão!',err)
        }
    }

    function convertXlsxToJson(file) {
        const { files } = file.target;

        const fileReader = new FileReader();
    
        fileReader.onload = (event) => {
          let data = [];
          try {
            const { result } = event.target;
            const workbook = XLSX.read(result, { type: 'binary' });
    
            for (const sheet in workbook.Sheets) {
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                data = data.concat(
                  XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
                );
              }
            }
          } catch (e) {
           console.log('Erro ao ler a planilha')
          }

          setExcelData(data);          
    
        };
        if (files[0] !== undefined) fileReader.readAsBinaryString(files[0]);
    }

    const formatPhValue = (value) => {
      if (typeof value === 'number') {
          return value.toLocaleString('pt-BR');
      } else if (typeof value === 'string') {
          const regex = /^\d+(,\d{0,1})?$/;
          if (regex.test(value)) {
              return value;
          }
      }
      return '';
    };

    const formatCarcassValue = (value) => {
      if (typeof value === 'number') {
          return value?.toLocaleString('pt-BR');
      } 
    };

    const formatGrValue = (value) => {
      if (typeof value === 'number') {
          return value.toFixed(0).replace('.', ',');
      } else if (typeof value === 'string') {
          const regex = /^\d+(,\d{0,1})?$/;
          if (regex.test(value)) {
              return value;
          }
      }
      return '';
    };


  const transformInputValue = (event) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace('.', ',');

    inputValue = inputValue.replace(/[^\d,]/g, '');
    const commas = inputValue.match(/,/g) || [];
    if (commas.length > 1) {
        inputValue = inputValue.slice(0, inputValue.lastIndexOf(','));
    }

    if (inputValue.length > 1 && inputValue.startsWith('0')) {
        inputValue = inputValue.slice(1);
    }

    if (inputValue.length > 3) {
        inputValue = inputValue.slice(0, 3);
    }

    if (parseFloat(inputValue) > 14) {
      inputValue = `${inputValue.charAt(0)},${inputValue.slice(1)}`;
  }

    event.target.value = inputValue;

    return event;
  };

  const transformCarcassValue = (event) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace('.', ',');

    inputValue = inputValue.replace(/[^\d,]/g, '');
    const commas = inputValue.match(/,/g) || [];
    if (commas.length > 1) {
        inputValue = inputValue.slice(1, inputValue.lastIndexOf(','));
    }

    if (inputValue.length > 1 && inputValue.startsWith('0')) {
        inputValue = inputValue.slice(1);
    }

    if (inputValue.length > 4) {
        inputValue = inputValue.slice(0, 4);
    }

    event.target.value = inputValue;

    return event;
  };


      function saveBatch(){
        if(data.length === 0){
          warn({ title: "Lote vazio", body: "Não há animais cadastrados no lote." })
        }else{

          let batchClass = handleColdShortening(data);

          let auxData = data.map((item) => {
            const temperatureIntervals = ['0~3', '3,1~6', '6,1~9', '9,1~12', '>12'];            
            
            if(!temperatureIntervals.includes(item.evaluations?.temperature) ){
              item.evaluations.temperature = Helpers.VerifyTemperatureInterval(item.evaluations.temperature)
            }

            return {
                animal: {
                  ...item.animal,
                    initial_temp: item.animal.initial_temp !== null ? parseInt(item.animal.initial_temp): null,
                    initial_pH: item.animal.initial_pH !== null ? parseFloat(String((item.animal.initial_pH)).replace(',', '.')).toFixed(1): null,
                    carcass_weight: item.animal.carcass_weight !== null ? parseFloat(String((item.animal.carcass_weight)).replace(',', '.')).toFixed(1): null,
                    batch_id: batchInfo.id,
                    supplier_id: batchInfo.supplier.id
                },
                evaluations: {
                  ...item.evaluations,
                    temperature: item.evaluations.temperature !== null ? Helpers.VerifyInverseTemperatureInterval(item.evaluations.temperature)  : null,
                    pH: item.evaluations.pH !== null || isNaN(item.evaluations.pH)  ? parseFloat(String(item.evaluations.pH).replace(',', '.')).toFixed(1) : null,
                    technician_id: technitianId
                },
                batch_classification: batchClass,
            };
        });

        if (batchClass === "REPROVADO" && (batchInfo.evaluation_status && batchInfo.evaluation_status.toLowerCase() !== batchClass.toLowerCase() )){
          warn({ 
            title:"Atenção",
            body:"Esse lote será reprovado, pois não cumpre as conformidades",
            firstButton: { label:'Revisar Informações', color:"#fff" },
            secondButton:{ label:'Entendi', color: '#A4A4A4', function: ()=> {
              sendToAPI(auxData);
              closeWarn()
            } }
          })
        }else if (batchClass === "APROVADO COM RESSALVAS" && (batchInfo.evaluation_status && batchInfo.evaluation_status.toLowerCase() !== batchClass.toLowerCase() )){
          warn({ 
            title:"Atenção",
            body:"O lote será aprovado com ressalvas e terá que passar por uma maturação de 10 dias antes da avaliação",
            firstButton: { label:'Revisar Informações', color:"#fff" },
            secondButton:{ label:'Entendi', color: '#A4A4A4', function: ()=> {
              sendToAPI(auxData)
              closeWarn()
            } }
          })
        }
        else sendToAPI(auxData);
      }
    }

      function sendToAPI(auxData){

        API.post('storeOrUpdateAnimals',{animals:auxData}).then(async(res)=>{
          let animalTempAvg = auxData.reduce((acc, animal) => {
            if (animal.animal.initial_temp !== null) {
              acc += animal.animal.initial_temp;
            }
            return acc;
          }, 0) / auxData.filter(animal => animal.animal.initial_temp !== null).length;

          animalTempAvg = parseFloat(animalTempAvg.toFixed(1));

          await API.put('batches/'+batchInfo.id,{batch:{temperature_average: animalTempAvg}})
          navigate('../batches');
        }).catch((err)=>{
          console.log('error',err)
        })    
      }

    return(
        <Container>
            <Title className="large">Animais</Title>
            <ContentContainer>
                <InputsContainer>
                    <ItemsContainer>
                        <InputTitle className="medium">ID Inicial (opcional)</InputTitle>
                        <Input
                            type="numeric"
                            value={initialIdToUpload}
                            onChange={(e) => {
                            if (e.target.value >= 0 && !isNaN(e.target.value))
                                setInitialIdToUpload(e.target.value)
                            }}
                        />
                    </ItemsContainer>
                    <ItemsContainer>
                        <InputTitle className="medium">ID Final (opcional)</InputTitle>
                        <Input 
                            type="numeric"
                            value={finalIdToUpload}
                            onChange={(e) => {
                            if (e.target.value >= 0 && !isNaN(e.target.value))
                                setFinalIdToUpload(e.target.value)
                            }}
                        />
                    </ItemsContainer>
                    <ItemsContainer>
                        <InputTitle className="medium">Planilha</InputTitle>
                        <SheetText className="small">Planilha padrão disponivel para download - <SheetLink className="small" href={planilhaPadrao} download='planilha_padrao_sheep'>baixe aqui</SheetLink></SheetText>
                        <SheetText className="small" style={{marginTop:'5px'}}>Upload da planilha preenchida</SheetText>
                        <FileContainer>
                        <SheetButton 
                            ref={fileRef}
                            className="small"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={(file) => {
                                if (
                                file
                                ) {
                                convertXlsxToJson(file);
                                }
                      }}/>
                        </FileContainer>
                    </ItemsContainer>
                </InputsContainer>
                  <WarningContainer>
                  {(data.length > 0) && (animalsWithInitial < Math.ceil(data.length*0.1))?
                   (<WarningContent>
                        <FaCircleExclamation style={{fontSize:'24px', marginRight:'10px', color:'#F1C62E'}}/>
                        <SheetText styles={{ width:'50%' }} className="medium">Pelo menos {Math.ceil(data.length*0.1)} {Math.ceil(data.length*0.1) > 1 ? 'animais devem ter pH inicial e temperatura inicial' : 'animal deve ter pH inicial e temperatura inicial (sujeito à desclassificação do lote)'}</SheetText>
                    </WarningContent>
                   ):
                   <WarningContent/>
                    }
                    <SearchInput placeholder="Buscar ID" value={searchId} onChange={handleSearchId}/>
                    </WarningContainer>


                <TableContainer>
                    <TableHeader>
                        <TableTitle className="medium">ID</TableTitle>
                        <TableTitle className="medium">Sexo</TableTitle>
                        <TableTitle className="medium">Dentição</TableTitle>
                        <TableTitle className="medium">PCQ</TableTitle>
                        <TableTitle className="medium">Dorper</TableTitle>
                        <TableTitle className="medium">pH Inicial</TableTitle>
                        <TableTitle className="medium">Temp. Inicial</TableTitle>
                        <TableTitle className="medium">GR(mm)</TableTitle>
                        <TableTitle className="medium">pH Final</TableTitle>
                        <TableTitle className="medium">Temp. Final</TableTitle>
                        <TableTitle className="medium">Marmoreio</TableTitle>
                        <TableTitle className="medium">&nbsp;</TableTitle>
                    </TableHeader>
                    <TableContent>
                        {data.map((e, index)=>{
                          return(
                            <ListContainer key={index}>
                                <ListItem className="medium" name="animal.carcass_id" placeholder="-" value={e.animal.carcass_id} onChange={(event) => handleInputChange(index, event)}/>
                                <ListItem className="medium" name="animal.sex" placeholder="-" value={e.animal.sex === 'femea' ? 'fêmea' : e.animal.sex} onChange={(event) => handleInputChange(index, event)}/>
                                <ListItem className="medium" name="animal.dentition" placeholder="-" value={e.animal.dentition} onChange={(event) => handleInputChange(index, event)}/>
                                <ListItem className="medium" name="animal.carcass_weight" placeholder="-" value={formatCarcassValue(e.animal.carcass_weight)} onChange={(event) => handleCarcassChange(index, transformCarcassValue(event))}/>
                                <div style={{ display:'flex', justifyContent:'center' }}>
                                  <StyledCheckbox disabled={!theresDorper} value={e.animal?.dorper} checked={data[index].animal.dorper} onClick={(event)=>{
                                    if(theresDorper){

                                      handleInputDorper({ index, name:'dorper', value:!(e.animal.dorper??false) })
                                    
                                    }else{
                                      warn({ 
                                        title:"Atenção", 
                                        body:"Para marcar um animal como Dorper, selecione Dorper em Raças na aba Pré-Abate.",
                                        secondButton:{ label:"Entendi", color:"#A4A4A4" },
                                        showCancelButton:false
                                      })
                                    }

                                    }}>
                                    <Icon viewBox="0 0 24 24">
                                        <polyline points="20 6 9 17 4 12" />
                                    </Icon>
                                  </StyledCheckbox>
                                </div>
                                <ListItem className="medium" name="animal.initial_pH" placeholder="-" value={formatPhValue(e.animal?.initial_pH)}  onChange={(event) => handleInputChange(index, transformInputValue(event))}/>
                                <ListItem className="medium" name="animal.initial_temp" placeholder="-" value={e.animal.initial_temp !== null && e.animal.initial_temp !== undefined? `${e.animal.initial_temp}` : ''} onChange={(event) => handleInputChange(index, event)}/>
                                <ListItem className="medium" name="evaluations.fat_thickness" placeholder="-" value={formatGrValue(e.evaluations?.fat_thickness) ? e.evaluations?.fat_thickness : ''} onChange={(event) => handleInputChange(index, event)}/>
                                <ListItem className="medium" name="evaluations.pH" placeholder="-" value={formatPhValue(e.evaluations?.pH)}  onChange={(event) => handleInputChange(index, transformInputValue(event))}/>
                                <ListItem className="medium" name="evaluations.temperature" placeholder="-" value={e.evaluations?.temperature ? e.evaluations?.temperature : '' } onChange={(event) => handleInputChange(index, event)}/>
                                <ListItem className="medium" name="evaluations.meat_marbling" placeholder="-" value={e.evaluations?.meat_marbling} onChange={(event) => handleMarblingChange(index, event)}/>
                                <GoTrash  
                                  size={20} 
                                  style={{color:'#275224', cursor:'pointer', textAlign:'center', marginLeft:'10px'}} 
                                  onClick={() => handleDeleteIndex(index)}
                                />
                                 {deleteModalIndex === index && (                             
                                      <ModalContainer onClick={handleCloseModal}>
                                          <DeleteModal ref={modalRef}>                                                    
                                          <ModalItensContainer>
                                                  <IoWarning size={40} style={{color:'#D8190f'}}/>                                                   
                                                  <ModalHeader className='medium'> Excluir animal </ModalHeader>
                                                  <ModalContent className='small'>Você tem certeza que deseja excluir este animal? Essa ação não poderá ser desfeita!</ModalContent>
                                                  <ModalActions>
                                                      <CancelButton
                                                          className="button"
                                                          style={{marginRight:'50px'}}
                                                          onClick={() => handleCloseModal(e)}
                                                      >
                                                          Cancelar
                                                      </CancelButton>
                                                      <ExcludeButton
                                                          className="button"
                                                          onClick={() => {handleDeleteAnimal(index)}}
                                                      >
                                                          Excluir
                                                      </ExcludeButton>
                                                  </ModalActions>
                                              </ModalItensContainer>
                                          </DeleteModal>
                                      </ModalContainer>
                                  )                                               
                                  }
                            </ListContainer>
                        )})}
                    </TableContent>
                </TableContainer>
                <ButtonsContainer>
                    <CustomButton backgroundColor={'#DB7D57'} margin={'0 40px 0 0'} onClick={()=> {
                      setData([])
                      fileRef.current.value = null;
                      }}>Limpar Lote</CustomButton>
                    <CustomButton backgroundColor={'#275224'} onClick={()=> saveBatch()}>Salvar Lote</CustomButton>
                </ButtonsContainer>
            </ContentContainer>
        </Container>
    )
}