import { format } from "date-fns"; 

const Helpers = {};

Helpers.formatDate = (date, region = 'pt') => {

  let d = '';

  if(region === 'pt'){
  d = new Date(date).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }else{
    var dia  = date.split("/")[0];
    var mes  = date.split("/")[1];
    var ano  = date.split("/")[2];
    d = new Date(ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2) + ' 00:01');
  }
  return d;
}



Helpers.VerifyCarcassWeight = (value) => {
    value = parseInt(value);
    return value
  }

  Helpers.VerifyTemperatureInterval = (value) => {
    let tempValue = parseFloat(value);

    if (isNaN(tempValue)) {
        return '-'; // Se o valor não for um número válido, retornamos null
    }

    let interval = ['0~3','3,1~6','6,1~9','9,1~12','>12', '-'];
    
    if (tempValue >= 0 && tempValue <= 3) {
        return interval[0];
    } else if (tempValue >= 3.1 && tempValue <= 6) {
        return interval[1];
    } else if (tempValue >= 6.1 && tempValue <= 9) {
        return interval[2];
    } else if (tempValue >= 9.1 && tempValue <= 12) {
        return interval[3];
    } else if(tempValue > 12){
        return interval[4];
    } 
} 

  Helpers.VerifyInverseTemperatureInterval = (value) => {
    let tempValue = value;
    
    if(String(tempValue) === '0~3'){
      return 0
    }else if(String(tempValue) === '3,1~6'){
      return 1;
    }else if(String(tempValue) === '6,1~9'){
      return 2;
    }else if(String(tempValue) === '9,1~12'){
      return 3;
    }else if(String(tempValue) === '>12'){
      return 4;
    }
  }

  Helpers.InverseVerifyTemperatureInterval = (index) => {
    switch(index) {
        case 0:
            return '0~3';
        case 1:
            return '3,1~6';
        case 2:
            return '6,1~9';
        case 3:
            return '9,1~12';
        case 4:
            return '>12';
    }
}

Helpers.preparePreSlaughterData = (data) =>{

  let emptyValues = {};
  
  Object.keys(data).map((k)=>{

    if(data[k] === '' || data[k] == null || data[k] == -1){
      emptyValues[k] = true;
    }

    });

  if(Object.keys(emptyValues).length > 0) return { message:"Incompleto", emptyValues};

  let payload = {
    max_age_declared: parseInt(data.maxAge),
    avg_live_weight: parseInt(data.aliveWeigth),
    shearing_status: parseInt(data.shear),
    supplier_id:data.userId,
    farm_id:parseInt(data.farm),
    compliance: Object.keys(data.compliance).length > 0?
    {...data.compliance}
    :
    {
      question_one: false,
      question_two: false,
      question_three: false,
      question_four: false,
      question_five: false
    }
  }

  return { message:'Sucesso', data: {...payload} };

}

Helpers.prepareBatchData = (data) =>{
  let emptyValues = {};
  
  Object.keys(data).map((k)=>{

    if(data[k] === '' || data[k] == null || data[k] == -1){
      emptyValues[k] = true;
    }

    });

  if(Object.keys(emptyValues).length > 0) return { message:"Incompleto", emptyValues};

  let payload = {
      slaughter_plant_id:data.slaughterPlant,
      processment_plant_id:data.processmentPlant,
      animals_quantity:data.animalsQuantity,
      ph_average:data.initialPh,
      hang_method:data.hangMethod === "Aquiles"?1:0,
      slaughter_date:data.slaughterDate
  }

  return { message:'Sucesso', data: {...payload} };



}

Helpers.formatAliveWeight = (value) =>{

  value = value.replace(/[^\d.,]/g, '');

        const dotsAndCommas = value.match(/[.,]/g) || [];
    
        if (dotsAndCommas.length > 1) {
            value = value.slice(0, value.lastIndexOf(dotsAndCommas.pop()));
          }

        if (value.length > 3) {
            value = value.slice(0, 3);
        }
  
  return value;

}

Helpers.formatMaxAge = (value) =>{

  value = value.replace(/[^\d.,]/g, '');

  const dotsAndCommas = value.match(/[.,]/g) || [];

  if (dotsAndCommas.length > 1) {
      value = value.slice(0, value.lastIndexOf(dotsAndCommas.pop()));
  }

  if (value.length > 2) {
      value = value.slice(0, 2);
  }

  return value;
}

Helpers.formatpH = (value) => {

  value = value.replace('.', ',');

  value = value.replace(/[^\d.]/g, '');

  const dots = value.match(/\,/g) || [];
  if (dots.length > 1) {
      value = value.slice(0, value.lastIndexOf(','));
  }

  if (value.length > 3) {
      value = value.slice(0, 3);
  }


  if (parseFloat(value) > 14) {
      value = `${value.charAt(0)},${value.slice(1)}`;
  }

  return value;
};


Helpers.URLtoBase64 = async (url) =>{

  const response = await fetch(url);
    const blob = await response.blob();
    
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export default Helpers;
