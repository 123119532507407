import styled from "styled-components";

export const Container = styled.div`
    width:calc(100% - 100px);
    height:calc(100% - 80px);
    display:flex;
    flex-direction:column;
    justify:content:center;
    padding: 30px 50px 50px 50px;

    @media screen and (max-width:1600px){
        padding: 30px 50px 50px 50px;
    }
`

export const Header = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-around;
    border-bottom: 1px solid #275224;
    margin-bottom:50px;
    
    @media screen and (max-width:1600px){
        margin-bottom:30px;
    }
    
`

export const HeaderTitle = styled.div`
    font-size:17px;
    color:#275224;
    padding-bottom:5px;
    cursor:pointer;
    
    &.active {
        border-bottom: 2px solid #275224;
    }
`