import React from "react";
import {Container} from './styles'

export default function Summary({data}){

    function formatFrequency(value) {
            return `${String(parseFloat(value) > 0 ? parseFloat(value).toFixed(1):parseFloat(value).toFixed(0) ).replace('.',',')}%`;
    }

    const GeneralInfoTable = ({data}) => {
        return (
            <table>
                <thead>
                    <tr>
                    <th>Total de Animais</th>
                    <th>ID Inicial</th>
                    <th>ID Final</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{data.totalAnimals??0}</td>
                    <td>{data.initialID??'-'}</td>
                    <td>{data.finalID??'-'}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const ProducerInfoTable = ({data}) =>{
        return(
            <>
            <div style={{paddingBottom: 3, fontSize:'16px', fontWeight:'bold'}}>Produtor</div>
            <table>
                <thead>
                <tr>
                    <th>Nome do Produtor</th>
                    <th>Fazenda</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{data.productor?.user.name??'-'}</td>
                    <td>{data.farm?.name??'-'}</td>
                </tr>
                </tbody>
            </table>
            </>
        )
    }

    const SexInfoTable = ({ data }) => {
        const sexData = Object.entries(data.sex);
        return (
            <>
            <div style={{paddingBottom: 3, fontSize:'16px', fontWeight:'bold'}}>Sexo</div>
            <table>
                <thead>
                <tr>
                    <th>Sexo</th>
                    <th>Quantidade</th>
                    <th>Frequência</th>
                </tr>
                </thead>
                <tbody>
                {sexData?.map(([sex, value]) => (
                    <tr key={sex}>
                    <td>{sex == 'femea'? "Fêmea": sex == "mc"? "Macho Castrado":"Macho Inteiro"}</td>
                    <td>{value.value}</td>
                    <td>{formatFrequency(value.freq)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </>
        );
    }

    const DentitionInfoTable = ({ data }) => {
        const dentitionData = Object.entries(data.dentition);
        return (
            <>
            <div style={{paddingBottom: 3, fontSize:'16px', fontWeight:'bold'}}>Dentição</div>
            <table>
                <thead>
                <tr>
                    <th>Dentição</th>
                    <th>Quantidade</th>
                    <th>Frequência</th>
                </tr>
                </thead>
                <tbody>
                {dentitionData.map(([dent, value]) => (
                    <tr key={dent}>
                    <td>{dent == 'dent0' ? 0 : dent == 'dent2' ? 2 : dent == 'dent4' ? 4 : dent == 'dent6' ? 6 : 8}</td>
                    <td>{value.value}</td>
                    <td>{formatFrequency(value.freq)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            
            </>
        );
    }

    const CarcassWeightInfoTable = ({ data }) => {
        const { min, max, avg } = data.carcass_weight;
        return (
            <>
            <div style={{paddingBottom: 3, fontSize:'16px', fontWeight:'bold'}}>Peso de Carcaça(kg)</div>
            <table>
                <thead>
                <tr>
                    <th>Média</th>
                    <th>Mínimo</th>
                    <th>Máximo</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{String(parseFloat(avg).toFixed(1)).replace('.',',')}</td>
                    <td>{String(parseFloat(min).toFixed(1)).replace('.',',')}</td>
                    <td>{String(parseFloat(max).toFixed(1)).replace('.',',')}</td>
                </tr>
                </tbody>
            </table>
            </>
        );
    }
      
    const Resposable = ({ data }) => {
        return(
        <>
            <div style={{paddingBottom: 3, fontSize:'16px', fontWeight:'bold'}}>Responsável pelo Cadastro</div>
            <table>
                <thead>
                <tr>
                    <th>Nome</th>
                    <th>Usuário</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{data.cadUser.name}</td>
                    <td>{data.cadUser.username}</td>
                </tr>
                </tbody>
            </table>
        </>
        )
    }

    return(
        <Container>
            <GeneralInfoTable data={data} />
            <ProducerInfoTable data={data} />
            {data.sex && data.dentition && data.carcass_weight?
            <>
            <SexInfoTable data={data} />
            <DentitionInfoTable data={data} />
            <CarcassWeightInfoTable data={data} />
            </>
            :
            <></>
            }
            <Resposable data={data}/>
        </Container>
    )
}
