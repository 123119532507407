import styled, {css, keyframes } from "styled-components";
import { FaCircleCheck, FaCircleXmark, FaCircleExclamation } from "react-icons/fa6";
import { PiDotsThreeCircleFill } from "react-icons/pi";


const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(255, 215, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(255, 215, 0, 0);
  }
`;

export const Container = styled.div`
    width:100% - 40px;
    height:100% - 50px;
    display:flex;
    flex-direction:column;
    justify:content:center;
    padding: 80px 70px 0px 70px;

    @media screen and (max-width:1919px){
        font-size:13px;
    }

    @media screen and (max-width:1600px){
        padding: 50px 60px 50px 70px;
        height:100% -20px;
    }

    @media (max-width: 1000px){
        padding:20px;
        height:100%;
        width:89%;
    }
`

export const Title = styled.div`
    font-size:40px;
    color:#275224;
    margin-bottom:50px;

    @media (max-width: 1000px){
        font-size:30px;
        margin-bottom:10px;
    }
`

export const BatchContainer = styled.div`
    width: 100%;
    height:20%;
    border-radius:10px 0 0 10px;
    display:flex;
    justify-content: space-between;
    align-items:center;
    background-color:#0000000D;
    padding: 30px 0;
    max-height:50px;
    overflow:hidden;
    border: ${props => props.dorper ? '2px solid #ffd700' : ''};
     ${props => props.dorper ? PulsatingBorder : ''};

     @media (max-width: 1000px){
        height:100%;
        padding:10px;
        justify-content:space-around;
        margin-bottom:10px;
        background-color:transparent;
        max-height:none;
    }

`

export const ItemContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:10%;   

     @media (max-width: 1000px){
        height:100%;
        width:${props => props.portrait ? '30%' : '20%'}; 
        background-color:#E6E6E6;
        border-radius:5px;
        padding:5px 20px;
    }
`

export const ForwardContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:10%;   

    @media (max-width: 1000px){
        height:100%;
        width:${props => props.portrait ? '30%' : '20%'};  
        padding:5px 20px;
    }
`


export const ItemTitle = styled.div` 
    text-align:center;
    color:#414141;
    margin-bottom:10px;  

    @media (max-width: 1000px){
        font-size:14px;
    }
`

export const Item = styled.div`  
    text-align:center;
    color:#575757;
    
    @media (max-width: 1000px){
        font-size:16px;
    }
`

export const IconsContainer = styled.div`
    display:flex;
    width:8%;
    align-items:center;
    justify-content: center;    

    @media (max-width: 1000px){
        width:${props => props.portrait ? '30%' : '20%'};
        padding:5px 20px;
    }
`

export const StatusContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-around;
    width:12%;
    background-color: ${props => props.evaluation_status === 'Reprovado' ? 'rgba(201, 49, 49, 0.3)' : '#1A275224'};
    padding:5px 10px;
    border-radius:30px; 
    text-align:center;
    color:#414141;

    @media screen and (max-width:1919px){
        font-size:13px;
    }

    @media (max-width: 1000px){
        flex-direction:column;
        width:${props => props.portrait ? '30%' : '20%'}; 
        height:${props => props.status === 'Aprovado com ressalvas' ? '60px' : '42px'};
        font-size:12px;
        // margin-right:58px;
        border-radius:50px;
        padding:5px 20px;
    }
`

export const CheckIcon = styled(FaCircleCheck)`
    color:#275224;
    font-size:40px;

    @media (max-width: 1000px){
        font-size:25px;
    }
`

export const XIcon = styled(FaCircleXmark)`
    color:#C93131;
    font-size:40px;

    @media (max-width: 1000px){
        font-size:30px;
    }
`

export const ExclamationIcon = styled(FaCircleExclamation)`
    color:#275224;
    font-size:40px;

    @media (max-width: 1000px){
        font-size:30px;
    }
`
export const ThreeDotsIcon = styled(PiDotsThreeCircleFill)`
    color:#606773;
    font-size:40px;

    @media (max-width: 1000px){
        font-size:30px;
    }
`

export const StatusItem = styled.div` 
    text-align:center;   

    @media (max-width: 1000px){
        margin-bottom:5px;
    }
`

export const EvaluationContainer = styled.div`
    display:flex;
    position:relative;
   
    @media (max-width: 1000px){
        height:100%;
        margin-bottom:30px;
    }
`

export const EvaluationStatus = styled.div`
    width: 10%;
    height:110px;
    background-color:#0000000D;
    border-radius:10px;
    display:flex;
    justify-content: space-around;
    align-items:center;
    padding: 30px 0;
    margin-left: 20px;
    max-height:50px;

    border:${props => props.dorper ? '2px solid #ffd700' : ''}; 
    ${props => props.dorper ? PulsatingBorder : ''};

     @media (max-width: 1000px){
        height:100%;
        max-height:none;
        width:${props => props.portrait ? '30%' : '20%'}; 
        padding:5px 20px;
        margin-top:${props => props.portrait ? '5px' : '0'};
        margin-left:0;
    }
`

export const Evaluation = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    text-align:center;
`
export const EvaluationBorder = styled.div`
    text-align:center;
    // background-color: ${props => props.status === 'FINISHED' ? 'rgba(12, 186, 0, 0.5)' : 'rgba(241, 198, 46)'};
    background-color:#D9D9D9;
    border-radius: 30px;
    padding:5px 10px;
    width:80%;
    font-size:11px;
    color:#575757;
`

export const EvaluationIcon = styled.div`
    display:flex;
`
export const BatchStatus = styled.div`
    width: 10%;
    height:110px;
    background-color:#0000000D;
    border-radius:0 10px 10px 0;
    display:flex;
    justify-content: space-around;
    align-items:center;
    padding: 30px 0;
    max-height:50px;
    border: ${props => props.dorper ? '2px solid #ffd700' : ''};
     ${props => props.dorper ? PulsatingBorder : ''};

`

export const BatchEvaluationContainer = styled.div`
    width: 100%;
    height:20%;
    background-color:#0000000D;
    border-radius:10px;
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding: 30px 0;
    margin-bottom:20px;
    max-height:50px;    
    border:${props => props.dorper ? '2px solid #ffd700' : ''}; 
    ${props => props.dorper ? PulsatingBorder : ''};
`

export const Modal = styled.div`
    transition: opacity 0.5s ease-in-out;
    background-color:#D9D9D9;
    border-radius:20px;
    padding: 10px;
    width:120px;

    @media (max-width: 1000px){
       height:auto;
`

export const Text = styled.div`
   
    height:20px;
    display:flex;
    align-items:center;
    justify-content:left;
    padding:10px;
    cursor:pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color:#275224;
    transition: font-size 0.2s ease;
    
    @media (min-width:1000px){
    &:hover {
        font-size: 18px;
        size:28px;

        .icon{
            font-size: 27px; 
        }
      }
    }

      @media (max-width: 1000px){
        font-size:14px;
        height:15px;
        .icon{
            font-size:20px;
        }
    }
`

export const IconWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 25px; 
    transition: font-size 0.2s ease;    
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items:center;
    justify-content:center;
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    z-index: 1000;
`

export const DeleteModal = styled.div`
    font-size: 12px;
    width:400px;
    height:200px;
    background-color:rgba(255, 255, 255);
    margin-bottom:200px;
    border-radius:20px;
    padding:10px;
`

export const ContentContainer = styled.div`
      width:100%;
      height:100%;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
`

export const CancelButton = styled.button`
    width:100px;
    height:40px;
    border: 1px solid #D9D9D9;
    border-radius:3px;
    background-color:#D9D9D9;
    color:#000;
    cursor:pointer;
`

export const ExcludeButton = styled.button`
    width:100px;
    height:40px;
    border: 1px solid #d25650;
    border-radius:2px;
    color:#FFF;
    cursor:pointer;
    background-color:#d25650;
`

export const ModalHeader = styled.div`
    font-size: 18px;
    text-align: center;
    padding: 15px 5px;
    color:#000;
`
export const ModalContent = styled.div`
    text-align: center;
    color:#000;
    font-size:15px;
`

export const ModalActions = styled.div`
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    display:flex;
    align-items:center;
    justify-content:center;
`

export const PulsatingBorder = css`
  animation: ${pulse} 2s infinite;
`;

export const BatchContainerMobile = styled.div`
    width: 100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding: 10px;
    overflow:hidden;
    background-color:#0000000D;
    border-radius:10px;

    border:${props => props.dorper ? '2px solid #ffd700' : ''}; 
    ${props => props.dorper ? PulsatingBorder : ''};
`

export const DorperImage = styled.img`
  width: 60px;
  height: auto;
  border-radius: 5px;
  ${props => props.dorper ? PulsatingBorder : ''};
`

export const BorderContainer = styled.div`
    display:flex;
    width:100%;
    height:100%;
    border:${props => props.dorper ? '2px solid #ffd700' : ''}; 
    ${props => props.dorper ? PulsatingBorder : ''};
    padding:0;
    margin-bottom:20px;
    border-radius:10px;
`

export const NoBatchesDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #A9A9A9;
    margin-top: 100px;
    font-size:24px
`

