import React, { useState, useEffect } from 'react';
import { Container, Header, HeaderTitle } from './styles';
import PreSlaughter from '../../components/PreSlaughter';
import Batch from '../../components/Batch';
import Animals from '../../components/Animals';
import { useDispatch, useSelector } from 'react-redux';
import { useWarn } from '../../context/Modal';
import { resetBatch } from '../../store/modules/batch/actions';


export default function Register(){

    const { batch } = useSelector((state)=>state.Reducers);
    const { warn } = useWarn();
    const dispatch = useDispatch();

    const [screen, setScreen] = useState('Pré-Abate');
    const [allErrors, setAllErrors] = useState({});
    const [batchIsRegistred, setBatchIsRegistered] = useState(batch.id);

    // Para o caso de uma pessoa entrar na tela Cadastrar vinda 
    // da tela de avaliação
    useEffect(()=>{
        if(batch.status !== "CREATED"){
            dispatch(resetBatch());
            setBatchIsRegistered(false);
        }
    },[])

    const handleNextToBatchButtonClick = () => {
        setScreen('Lote');
    };

    const handleNextToAnimalsButtonClick = () => {
        setScreen('Animais');
    };

    const handleErrors = (err) =>{
        if(err && Object.keys(err).length > 0){
        setAllErrors({...allErrors, ...err});
        }else setAllErrors({})
    }

    return(
        <Container>
            <Header>
                <HeaderTitle className={`medium ${screen === 'Pré-Abate' ? 'active' : ''}`}
                 onClick={() => setScreen('Pré-Abate')}>
                    Pré-Abate
                </HeaderTitle>
                <HeaderTitle className={`medium ${screen === 'Lote' ? 'active' : ''}`}
                 onClick={() => setScreen('Lote')}>
                    Lote
                </HeaderTitle>
                <HeaderTitle className={`medium ${screen === 'Animais' ? 'active' : ''}`}
                 onClick={() => {
                    if(batchIsRegistred) setScreen('Animais')
                    else warn({ 
                        title: "Atenção", 
                        body: <p>
                                Preencha e salve <u>todas</u> as informações de
                                &nbsp;<strong>Pré-Abate</strong> e <strong>Lote</strong> 
                                &nbsp;antes de prosseguir
                            .</p>,
                        showCancelButton:false,
                        secondButton:{ color:"#A4A4A4", label:"Entendi" } 
                    })
                    }}>
                    Animais
                </HeaderTitle>
            </Header>
                { screen === 'Pré-Abate' ? 
                <PreSlaughter allErrors={allErrors} onNextButtonClick={handleNextToBatchButtonClick}/>
                : screen === 'Lote' ?
                <Batch 
                onNextButtonClick={handleNextToAnimalsButtonClick} 
                setBatchIsRegistered={setBatchIsRegistered} 
                allErrors={allErrors} 
                setAllErrors={handleErrors} 
                backScreen={()=>setScreen('Pré-Abate')}
                /> 
                : 
                <Animals batchInfo={batch}/>
                }
        </Container>
    )

}