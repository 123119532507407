import React from 'react';
import { Modal, ModalContent } from './styles';

const ModalPattern = ({ show, children, width }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal>
    <ModalContent style={{width: `${width}%`}}>
      {children}
    </ModalContent>
  </Modal>
  );
};

export default ModalPattern;