import React, { useState, useRef, useEffect } from 'react';
import { BatchContainer, IconsContainer, Item, ItemContainer, StatusItem, StatusContainer, ItemTitle, EvaluationContainer,
        EvaluationStatus, Evaluation, EvaluationBorder, EvaluationIcon, Modal, Text, IconWrapper, DeleteModal,
        ModalHeader, ModalActions, ModalContainer, ModalContent, ContentContainer, CancelButton, ExcludeButton, BatchContainerMobile,
         CheckIcon, XIcon, ExclamationIcon, DorperImage, ForwardContainer, ThreeDotsIcon } from '../styles';

import { IoArrowForwardCircle, IoWarning } from "react-icons/io5";
import { HiDotsVertical } from "react-icons/hi";

import { TbNotes } from "react-icons/tb";
import { GoTrash } from "react-icons/go";
import { PiDownload, PiFileImageFill } from "react-icons/pi";
import Popup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';
import dorper from '../../../assets/images/dorperIcon.png'
import Cookie from 'js-cookie';
import Helpers from '../../../services/helpers/index';
import API from '../../../services/API/index';
import { useWarn } from "../../../context/Modal";
import Summary from '../../../components/Batch/Summary'; 
import { S3 } from '../../../services/AWS';
import download from 'downloadjs';

// REDUX
import {useDispatch} from 'react-redux'
import {setBatchToEvaluation } from '../../../store/modules/batch/actions';
import Constants from '../constants';
import downloadCustomExcel from '../../../components/DownloadExcel';

export default function MobileBatches(){
    // console.log('height, width', window.innerHeight, window.innerWidth)
    
    const [open, setOpen] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );
    const [batches,setBatches] = useState([]);

    const modalRef = useRef();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { warn } = useWarn();

    async function batchesData(){
        let batchesInfo = []
        API.get('batchesByCompany/' + Cookie.get('sheep-company-id')).then((res)=>{
            if(res.data.length > 0){
                res.data.map((item)=>
                    batchesInfo.push({
                        id : item.id,
                        processment_plants_name: item.processmentPlant,
                        slaughter_plants_name: item.slaughterPlant,
                        processment_plants: item.procesmenPlantNameAndId,
                        slaughter_plants: item.slaughterPlantNameAndId,
                        animals_quantity: item.animalsQtt,
                        slaughter_date: Helpers.formatDate(item.slaughterDate),
                        creation_date:Helpers.formatDate(item.createdAt),
                        status: item.status,
                        evaluation_status:item.classification === "APROVADO"? "Aprovado" : item.classification === "REPROVADO" ? "Reprovado" :item.classification?"Aprovado com ressalvas":"Rascunho" ,
                        isDorper: item.isDorper,
                        supplier: item.supplier,
                        farm: item.farm,
                        productor:item.productor,
                        compliance:item.compliances,
                        races: item.races,
                        deaths: item.deaths,
                        observation: item.observation,
                        hang_method: item.hangMethod,
                        avg_live_weight: item.liveWeight,
                        max_age_declared: item.maxAge,
                        compliance_id: item.compliances.id,
                        shearing_status: item.shearing,
                        ph_average: parseFloat(item.phinitial).toFixed(1),
                        media: item.media??null,
                        media_id: item.media?item.media.id:null
                    })
                )

                batchesInfo.sort((a,b)=> b.id - a.id)
                setBatches(batchesInfo)
            }
        })
    }

    useEffect(()=>{
        batchesData()
    },[])

    const clearLocalStorage = () => {
        localStorage.removeItem('batchState');
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            clearLocalStorage();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1000);
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    function organizeRows(data){

        let organizedRows = {
            animal:[],
            batch:[],
        };

        const modifyCuttingMeatColor = (color) => {
            return color === 'Laranja' ? 'Amarelo' : color;
        };

        data.animal.forEach((animal) => {
            const a = { ...animal };
            let animalRow = [];
    
            Constants.animal.forEach((column) => {
                animalRow.push(modifyCuttingMeatColor(a[column.key]));
            });
            organizedRows.animal.push(animalRow);
        });
    
        data.batch.forEach((batch) => {
            const b = { ...batch };
            let batchRow = [];
    
            Constants.batch.forEach((column) => {
                batchRow.push(b[column.key]);
            });
            organizedRows.batch.push(batchRow);
        });
    
        return organizedRows;
    }

    async function downloadBatchExcel(batchId){

        const dataRows = await API.post('dataSheetFile', { batchId });
        
        if(dataRows.status === 200){
            let data = organizeRows(dataRows.data);
            let sheetData = [
            {
                columns: Constants.animal.map((item)=> item.name),
                data:data.animal,
                sheetName:'Animais'
            },
            {
                columns:Constants.batch.map((item)=> item.name),
                data:data.batch,
                sheetName:'Lote'
            }
            ]

            downloadCustomExcel(sheetData, `Lote-${batchId}`);
        }else{
            alert("Erro ao baixar a planilha");
        }
    }

    async function downloadMedia(mediaArray) {
        for (const media of mediaArray) {
            try {
                const name = media.sheep_media_url.split("amazonaws.com/")[1];
                const params = {
                    Bucket: 'sheep-medias',
                    Key: name,
                };
        
                const data = S3.getSignedUrl('getObject', params);
                const response = await fetch(data);
                const blob = await response.blob();
        
                download(blob, name, media.type);
                
            } catch (error) {
                console.log('Error downloading media:', error.message);
            }
        }
    }
          

    async function softDeleteBatch(batch){
        if(batch.status === 'CREATED'){
            await  API.get(`softBatchDelete/${batch.id}`).then( _ => {
                setBatches(batches => batches.filter(b => b.id !== batch.id))
                setOpen(false)
              }).catch((error)=>{
                console.log(error);
              })
        }else{
            alert("Não é possivel deletar o lote pois ele já foi finalizado ou está em progresso")

            setOpen(false)
        }
    }
      
    const handleCloseModal = (e) => {
        if(modalRef.current && !modalRef.current.contains(e.target))
        setOpen(false)
    }


    const handleNavigateToEvaluation = async (batch) => {
        const formattedDate = encodeURIComponent(batch.slaughter_date);            
        dispatch(setBatchToEvaluation({...batch, status: "SENT"}));

        localStorage.setItem('batchState', JSON.stringify(batch.id));

        if(batch.status === 'CREATED'){
            let batchAux = {status: 'SENT',compliance: {}}
            await API.put('/batches/' + batch.id, {batch: batchAux, races: []}).then(()=>{
                batchesData()
                batch.batchWillReciveUpdate = false
                navigate(`/Evaluation/?id=${batch.id}&date=${formattedDate}&productor=${batch.productor.user.name}`);
            })
        }else{
            batch.batchWillReciveUpdate = false
            navigate(`/Evaluation/?id=${batch.id}&date=${formattedDate}&productor=${batch.productor.user.name}`);
        }
              
    };

    const handleSummary = async (batch)  => {
        try {
        
        let animalInfo = (await API.get(`/getAnimalsFromBatch/${batch.id}`)).data

            animalInfo.productor = batch.productor
            animalInfo.farm = batch.farm
            animalInfo.cadUser = batch.supplier
            warn({ title: "Dados sobre o lote", body:<Summary data={animalInfo}/>, size:1000, showCancelButton:false, secondButton:{label:'Fechar', color:'#275224'},marginBottom: '20px', marginTop: window.innerHeight <= 618 ? '450px' : '220px' })
        } catch (error) {
            console.error('handle summary error', error)
        }
    }

    return(
            <div>
                {batches.map((info) => (
            <>
                {(info.status !== 'FINISHED' && info.status !== 'SENT') || info.evaluation_status === '' ? 
                    <div>
                        <div style={{display: 'flex',alignContent:'flex-end', justifyContent:'flex-end', marginBottom:'.2%', marginRight:'.3%'}}>
                            {info.isDorper ? <DorperImage src={dorper} alt='dorper' dorper={info.isDorper}/>: <></>}
                        </div>
                        <EvaluationContainer >
                            <BatchContainerMobile dorper={info.isDorper}>
                                <BatchContainer>
                                    <ItemContainer portrait={isPortrait}>
                                        <ItemTitle className='medium' >Lote</ItemTitle>
                                        <Item className='small' >{info.id}</Item>    
                                    </ItemContainer>
                                    <ItemContainer portrait={isPortrait}>
                                        <ItemTitle className='medium'>{isMobile ? 'Planta Abate' : 'Planta de Abate'}</ItemTitle>
                                        <Item className='small'>{info.slaughter_plants_name}</Item>
                                    </ItemContainer>
                                </BatchContainer>

                                <BatchContainer>
                                    <ItemContainer  portrait={isPortrait}>
                                        <ItemTitle className='medium'>Processamento</ItemTitle>
                                        <Item className='small'>{info.processment_plants_name}</Item>
                                    </ItemContainer>                                    
                                    <ItemContainer portrait={isPortrait}>
                                        <ItemTitle className='medium'>Quantidade</ItemTitle>
                                        <Item className='small'>{info.animals_quantity}</Item>
                                    </ItemContainer>
                                </BatchContainer>

                                <BatchContainer>
                                    <ItemContainer portrait={isPortrait}>
                                        <ItemTitle className='medium'>Abate</ItemTitle>
                                        <Item className='small'>{info.slaughter_date}</Item>
                                    </ItemContainer>                              
                                    <ItemContainer portrait={isPortrait}>
                                        <ItemTitle className='medium'>Cadastro</ItemTitle>
                                        <Item className='small'>{info.creation_date}</Item>
                                    </ItemContainer>
                                </BatchContainer>

                                <BatchContainer>
                                    <StatusContainer status={info.evaluation_status} portrait={isPortrait}>
                                        <div>
                                            <StatusItem className='medium'>{info.evaluation_status}</StatusItem>
                                        </div>
                                        {info.evaluation_status === 'Aprovado' ? <CheckIcon/> :
                                        info.evaluation_status === 'Reprovado' ? <XIcon/> :
                                        info.evaluation_status === 'Rascunho'? <ThreeDotsIcon/>:
                                        <ExclamationIcon/>}
                                    </StatusContainer>               
                                    <Popup
                                        trigger={
                                            <IconsContainer portrait={isPortrait}>
                                                <HiDotsVertical size={30} style={{color:'#275224', cursor:'pointer'}} />
                                            </IconsContainer>  
                                        }
                                        position={['top center', 'bottom center']}
                                        closeOnDocumentClick
                                        keepTooltipInside=".tooltipBoundary"
                                        closeOnEscape={true}
                                    >
                                        {
                                            close => (
                                    
                                        <Modal>
                                            <Text className='small' onClick={()=>{close(); handleSummary(info)}}>
                                                <IconWrapper className='icon'>
                                                    <TbNotes  style={{marginRight:'5px'}}/>
                                                </IconWrapper>
                                                    Resumo
                                            </Text>

                                            <Text className='small' onClick={() => setOpen(o => !o)}>
                                                <IconWrapper className='icon' >
                                                    <GoTrash style={{marginRight:'5px'}}/>
                                                </IconWrapper>
                                                    Excluir
                                            </Text>

                                                {open && (                             
                                                    <ModalContainer onClick={handleCloseModal}>
                                                        <DeleteModal ref={modalRef}> 
                                                            <ContentContainer>
                                                                <IoWarning size={40} style={{color:'#D8190f'}}/>                                                   
                                                                <ModalHeader className='medium'> Excluir lote </ModalHeader>
                                                                <ModalContent className='small'>Você tem certeza que deseja excluir este lote? Essa ação não poderá ser desfeita!</ModalContent>
                                                                <ModalActions>
                                                                    <CancelButton
                                                                        className="button"
                                                                        style={{marginRight:'50px'}}
                                                                        onClick={() => {setOpen(false); close();}}
                                                                    >
                                                                        Cancelar
                                                                    </CancelButton>
                                                                    <ExcludeButton
                                                                        className="button"
                                                                        onClick={() => {setOpen(false);softDeleteBatch(info); close();}}
                                                                    >
                                                                        Excluir
                                                                    </ExcludeButton>
                                                                </ModalActions>
                                                            </ContentContainer>
                                                        </DeleteModal>
                                                    </ModalContainer>
                                                )
                                                }
                                            { info.evaluation_status !== 'Rascunho'?
                                            <>
                                            <Text className='small' onClick={()=>{downloadBatchExcel(info.id)}}>
                                                <IconWrapper className='icon'>
                                                    <PiDownload style={{marginRight:'5px'}}/>
                                                </IconWrapper>
                                                    Relatório
                                            </Text>
                                            {info.media&&(
                                                <Text className='small' onClick={()=>downloadMedia(info.media)}>
                                                    <IconWrapper className='icon'>
                                                        <PiFileImageFill style={{marginRight:'5px'}}/>
                                                    </IconWrapper>
                                                    Mídia
                                                </Text>)
                                            }
                                        </>
                                            :
                                            <></>}
                                        </Modal>
                                    )
                                    }
                                    </Popup>                                                 
                                    
                                </BatchContainer>
                                <BatchContainer>
                                    {info.status === 'Reprovado' || info.evaluation_status === 'Rascunho'? <IconsContainer portrait={isPortrait}></IconsContainer> :
                                        <ForwardContainer onClick={() =>handleNavigateToEvaluation(info)} portrait={isPortrait}>
                                            <IoArrowForwardCircle size={60} style={{color:'#275224', cursor:'pointer'}}/>
                                        </ForwardContainer>
                                    }    
                                </BatchContainer>
                            </BatchContainerMobile>
                        </EvaluationContainer>                
                    </div>                       
                : 
                <> 
                    <div style={{display: 'flex',alignContent:'flex-end', justifyContent:'flex-end', marginBottom:'.2%', marginRight:'.3%'}}>
                        {info.isDorper ? <DorperImage src={dorper} alt='dorper' dorper={info.isDorper}/>: <></>}
                    </div>
                    <EvaluationContainer>
                        <BatchContainerMobile dorper={info.isDorper}>
                            <BatchContainer>
                                <ItemContainer portrait={isPortrait}>
                                    <ItemTitle className='medium' >Lote</ItemTitle>
                                    <Item className='small' >{info.id}</Item>    
                                </ItemContainer>

                                <ItemContainer portrait={isPortrait}>
                                    <ItemTitle className='medium'>{isMobile ? 'Abate' : 'Planta de Abate'}</ItemTitle>
                                    <Item className='small'>{info.slaughter_plants_name}</Item>
                                </ItemContainer>
                            </BatchContainer>
                            <BatchContainer>
                                <ItemContainer  portrait={isPortrait}>
                                    <ItemTitle className='medium'>Processamento</ItemTitle>
                                    <Item className='small'>{info.processment_plants_name}</Item>
                                </ItemContainer>                               

                                <ItemContainer portrait={isPortrait}>
                                    <ItemTitle className='medium'>Quantidade</ItemTitle>
                                    <Item className='small'>{info.animals_quantity}</Item>
                                </ItemContainer>
                            </BatchContainer>
                            <BatchContainer>
                                <ItemContainer portrait={isPortrait}>
                                    <ItemTitle className='medium'>Abate</ItemTitle>
                                    <Item className='small'>{info.slaughter_date}</Item>
                                </ItemContainer>
                                
                                <ItemContainer portrait={isPortrait}>
                                    <ItemTitle className='medium'>Cadastro</ItemTitle>
                                    <Item className='small'>{info.creation_date}</Item>
                                </ItemContainer>
                            </BatchContainer>
                            <BatchContainer>
                                <StatusContainer status={info.evaluation_status} portrait={isPortrait}>
                                    <div style={{width:'100%'}}>
                                        <StatusItem className='medium'>{info.evaluation_status}</StatusItem>
                                    </div>

                                    {info.evaluation_status === 'Aprovado' ? <CheckIcon/> :
                                    <ExclamationIcon size={40} style={{color:'#275224'}}/>}
                                </StatusContainer>  
                                <Popup
                                        trigger={
                                            <IconsContainer portrait={isPortrait}>
                                                <HiDotsVertical size={30} style={{color:'#275224', cursor:'pointer'}} />
                                            </IconsContainer>  
                                        }
                                        position={['top center', 'bottom center']}
                                        closeOnDocumentClick
                                        keepTooltipInside=".tooltipBoundary"
                                        closeOnEscape={true}
                                    >
                                        {
                                            close => (
                                    
                                        <Modal>
                                            <Text className='small' onClick={()=>{close(); handleSummary(info)}}>
                                                <IconWrapper className='icon'>
                                                    <TbNotes  style={{marginRight:'5px'}}/>
                                                </IconWrapper>
                                                    Resumo
                                            </Text>

                                            {info.status !== 'FINISHED' && info.status !== 'SENT' && (  
                                                <>
                                            <Text className='small' onClick={() => setOpen(o => !o)}>
                                                            <IconWrapper className='icon' >
                                                                <GoTrash style={{marginRight:'5px'}}/>
                                                            </IconWrapper>
                                                                Excluir
                                            </Text>

                                            </>                  
                                            )}
                                                {open && (                             
                                                    <ModalContainer onClick={handleCloseModal}>
                                                        <DeleteModal ref={modalRef}> 
                                                            <ContentContainer>
                                                                <IoWarning size={40} style={{color:'#D8190f'}}/>                                                   
                                                                <ModalHeader className='medium'> Excluir lote </ModalHeader>
                                                                <ModalContent className='small'>Você tem certeza que deseja excluir este lote? Essa ação não poderá ser desfeita!</ModalContent>
                                                                <ModalActions>
                                                                    <CancelButton
                                                                        className="button"
                                                                        style={{marginRight:'50px'}}
                                                                        onClick={() => {setOpen(false); close();}}
                                                                    >
                                                                        Cancelar
                                                                    </CancelButton>
                                                                    <ExcludeButton
                                                                        className="button"
                                                                        onClick={() => {setOpen(false);softDeleteBatch(info); close();}}
                                                                    >
                                                                        Excluir
                                                                    </ExcludeButton>
                                                                </ModalActions>
                                                            </ContentContainer>
                                                        </DeleteModal>
                                                    </ModalContainer>
                                                )
                                                }
                                            { info.evaluation_status !== 'Rascunho'?
                                            <>
                                            <Text className='small' onClick={()=>{downloadBatchExcel(info.id)}}>
                                                <IconWrapper className='icon'>
                                                    <PiDownload style={{marginRight:'5px'}}/>
                                                </IconWrapper>
                                                    Relatório
                                            </Text>
                                            {info.media&&(
                                                <Text className='small' onClick={()=>downloadMedia(info.media)}>
                                                    <IconWrapper className='icon'>
                                                        <PiFileImageFill style={{marginRight:'5px'}}/>
                                                    </IconWrapper>
                                                    Mídia
                                                </Text>)
                                            }
                                        </>
                                            :
                                            <></>}
                                        </Modal>
                                    )
                                    }
                                    </Popup>             
                            </BatchContainer>
                            <BatchContainer>
                                <EvaluationStatus portrait={isPortrait}>
                                    {info.status === 'FINISHED' ?
                                        <Evaluation>
                                            <EvaluationBorder status={info.status}>
                                                Finalizado
                                            </EvaluationBorder>
                                            <EvaluationIcon onClick={() =>handleNavigateToEvaluation(info)}>
                                                    <IoArrowForwardCircle size={45} style={{color:'rgba(8, 117, 1)', marginTop:'5px'}}/>
                                            </EvaluationIcon>
                                        </Evaluation>
                                        : 
                                        <Evaluation>
                                            <EvaluationBorder status={info.status}>
                                                    Andamento
                                                </EvaluationBorder>
                                                <EvaluationIcon onClick={() =>handleNavigateToEvaluation(info)}>
                                                    <IoArrowForwardCircle size={45} style={{color:'F1C62E', cursor:'pointer'}}/>
                                                </EvaluationIcon>
                                            </Evaluation>
                                    }                                             
                                </EvaluationStatus>
                            </BatchContainer>

                        </BatchContainerMobile>
                    </EvaluationContainer>
                </>
                } 
            </>
                ))}
            </div>
    )

}